import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Weal from "../../images/wealth-1.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Wealth = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="section-1 " id="wealth">
        <div className="container-fluid Magg pb-3 ">
          <div
            className="row flex-column-reverse flex-lg-row "
            data-aos="fade-up"
          >
            <div className="col-md-1 my-auto"></div>
            <div className="col-md-5 my-auto ">
              <h2 className=" cut-1 ">01</h2>
              <p className="cut-2">Philosophy</p>
              <h2 className="cut-3">Omega's Mission</h2>
              <p className="cut-4 text-justify">
                Omega Wealth Management is a Family Office that offer a truly
                client centric platform with high quality solutions disruptive
                from the big players in the industry. We strive to be a direct,
                responsive, and imaginative partner with our customers.
                <br />
                <br /> Omega’s mission is to preserve, grow and pass down wealth
                to the next generation. Before any financial commitment,
                understanding a customer and defining his profile is paramount.
                Omega’s disruptiveness comes from its systematic process approach.
              </p>

              {/* <h4 className="cut-5">
                discover <br />
                more
              </h4> */}
              <a href="#scroll">
                {" "}
                <p className="cut-6">SCROL DOWN ——— </p>
              </a>
            </div>
            <div className="col-md-5 speed  my-auto ">
              <div>
                <img className="weal img-fluid " src={Weal} alt="image"></img>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wealth;

import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Rigg from "../../images/wealth-4.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Rigorous = () => {

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="section-1 pt-5">
        <div
          className="container-fluid Magg-1111 
         "
          id="ahead"
        >
          <div className="row my-auto" data-aos="fade-up">
            {/* <div className="col-md-1 my-auto"></div> */}
            <div className="col-md-6 speedo my-auto gx-0">
              <div>
                <img
                  className="weal-111 img-fluid "
                  src={Rigg}
                  alt="image"
                ></img>
                <a href="#value">
                  <p className="cut-116">SCROL DOWN ——— </p>
                </a>
              </div>
            </div>
            <div className="col-md-5   my-auto rangss">
              <h2 className=" cut-1 ">03</h2>
              <p className="cut-2">Philosophy</p>
              <h2 className="cut-31" style={{fontSize:'6vh'}}>Processes: A Fresh & Rigorous Approach</h2>
              <p className="cut-4 text-justify">
              All successes have in common discipline, drive, and internal hurdles that compel them to challenge their viewpoints.
                We use the resource of multiple platforms, i.e.  inhouse social
                media, investment committee and quant portfolios are a mix of
                different approaches: Technical, Fundamental, Macro, Quant, and
                Financial. They all have two features: (i) to
                systematically filter our investment suggestions, (ii) staying
                away from the herd and the latest hype.
              </p>

              <h4 className="cut-5" style={{ cursor: 'pointer' }}><a onClick={() => navigate('/process')}>discover <br /> more</a></h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rigorous;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import Gen from "./images/gen-1.jpeg";

import Duret from "./Duret";
import AOS from "aos";
import "aos/dist/aos.css";

const Geneva = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <Duret /> */}
      <section className="section-1 pt-3">
        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div className="row robo flex-column-reverse flex-lg-row">
            <div className="col-md-6 ">
              <div className="nom">
                <img className="Ethic" src={Gen} alt="logo" />
              </div>
            </div>
            <div className=" col-md-6 pt-3">
              <h2 className="Along ">
                Omega Geneva recently identified various alternative funds.
              </h2>
              .
              <p className="phillo-1 pt-4">
                1. European Venture debt Investor has access to a closed end
                European DEBT portfolio receiving an attractive cash yield
                (9-15%), risk collateralized with top seniority in the
                liquidation waterfall, combined with free warrants in the best
                performing European based technology and innovation companies.
                Rare opportunity.
                <br />
                2. European Special Opportunities fund European OPPORTUNISTIC
                credit fund targeting 10% with stable returns and uncorrelated
                risk factor coupled with a strong downside protection feature.
                Fund has a short portfolio duration (2.8 years), is managed by a
                London based entity and includes diversified loan investment
                strategies essentially based on a legal disruption rationale.
                <br />
                3. USA, Life Insurance fund Product based on Human longevity.
                Open end US based unlisted fund on life insurance policiesbought
                “en bloc” on 2nd and 3rd markets. Highly diversified portfolio
                (LE, carrier, ratings,location…). Policies are “cherry picked”
                and actuarial probability calculations outsourced. De-correlated
                product with outstanding risk/reward ratio. Past performance
                varied between 22% and 15%, risk at 0.7% only. Policies are
                marked to market.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Geneva;

import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import bor from "./images/board-1.png";
import borr from "./images/board-2.png";
import borrr from "./images/board-3.png";
import Inside from "./Inside";
import { Document, Page } from "react-pdf";
import Swiper from "./Swiper";
import Macro from "./Macro";
import Portable from "./Portable"
import Vemo from "./Vemo"

import AOS from 'aos';
import 'aos/dist/aos.css'




const Articles = () => {
    useEffect(()=>{
        AOS.init({duration:2000})
            },[]);
    return (
        <>
            <section className="section-1 " id="article">
                <div className="container-fluid py-5 popp ">
                    <h1 className="text-center noen" data-aos="fade-up">News</h1>
                    <div className="row" data-aos="fade-up">
                        <div className="col-md-12">
                            <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active Invent" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">All</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link Invent" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Documents</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link Invent" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Articles</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link Invent" id="Video-tab" data-bs-toggle="tab" data-bs-target="#Video" type="button" role="tab" aria-controls="video" aria-selected="false">Video</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="container-fluid">
                                    <Swiper />
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="container-fluid">
                                <Portable />
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">

                                <div className="container-fluid">
                                <Macro />
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="Video" role="tabpanel" aria-labelledby="Video-tab">

                                <div className="container-fluid">
                                <Vemo />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>




                  


                </div>
            </section>
         
           
        </>
    );
};

export default Articles;

import React from "react";
import Office from "./Office";
import Docs from "./Docs";
import Articles from "./Articles";
import Inside from "./Inside";
import MetaTags from "./components/MetaTags";

function News() {
  return (
    <>

      <MetaTags
        title="Stay Updated With Our News - Omega"
        description="Get the latest news and updates from Omega. Stay informed with our breaking stories, in-depth analysis, and exclusive content. Don't miss out!"
      />

      {/* <Docs /> */}
      <Articles />
      <nope />
    </>
  );
}

export default News;

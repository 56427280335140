import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Flag from "./images/flag.jpg";

import Duret from "./Duret";
import AOS from "aos";
import "aos/dist/aos.css";

const Hold = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <Duret /> */}
      <section className="section-1 pt-3">
        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div className="row robo flex-column-reverse flex-lg-row">
            <div className="col-md-6 ">
              <div className="nom-0">
                <img className="Ethic" src={Flag} alt="logo" />
              </div>
            </div>
            <div className=" col-md-6 pt-3">
              <h2 className="Along ">
                Hold tight for volatility as trade turmoil rattles markets anew.
              </h2>
              .
              <p className="phillo-1 pt-4">
                Investors are standing by for a fresh bout of market turmoil as
                President Donald Trump turns up the heat on Beijing over trade.
                The standoff between the U.S. and China appeared to deepen at
                the weekend after Trump took to Twitter again, this time to say
                the Chinese may have felt they were “being beaten so badly” in
                the recent talks that it was better to drag their feet in hopes
                he would lose the 2020 election and get a better deal from the
                Democrats. Amid the prospect of retaliation from Beijing to the
                U.S. decision to slap higher tariffs on $200 billion of Chinese
                imports, traders are expecting a jump in volatility as investors
                dump stocks and other high-risk assets in favor of U.S.
                Treasuries, gold, the dollar, yen and Swiss franc. Nader Naeimi,
                who oversees about $1 billion in a dynamic market fund at AMP
                Capital Investors Ltd. in Sydney, said by email: • “The biggest
                problem is the huge disconnect with what markets have been
                hoping for and what is transpiring now. Markets had priced the
                best-case scenario and odds are shifting towards the worst-case
                scenario” • “China’s response was certainly not what risk
                markets were hoping for, so I expect huge volatility” at the
                Asia open • Note: China is planning how to retaliate and has
                told Washington that it must remove all extra tariffs, set
                targets for Chinese purchases of goods in line with real demand,
                and ensure that the text of the deal is “balanced” to ensure the
                “dignity” of both nations • “China demanding the U.S. drop the
                tariffs is setting the stage for a serious face-off” • “Economic
                tensions can now morph into military tensions between the two
                countries, and then with the U.S.-Iran flexing their muscles,
                oil prices are at risk of spiking up” • “For complacent
                equities, a perfect storm is brewing: tariffs, higher prices, a
                possible spike in oil prices in the face of fragile global
                growth. My asset allocation is gold, oil, inflation-linked bonds
                and defensive positioning” Mansoor Mohi-uddin, Singapore-based
                senior macro strategist at NatWest Markets, said in an email: •
                Forward-looking currency markets are reacting to the prospect of
                China’s trade surplus falling and Chinese corporates with $840
                billion of onshore foreign-exchange loans preemptively buying
                dollars o “Similar behavior last year caused the exchange rate
                to rise from 6.25 to 6.95” • The dollar’s strength against the
                yuan signals the greenback should remain strong versus the euro
                and other major currencies o A sharply higher greenback fueled
                by trade wars was a key threat U.S. investors raised when the
                NatWest team visited clients in New York, Seattle and California
                recently • “For the Federal Reserve — still unwilling to
                consider easing monetary policy — a surge in the dollar may
                become a risk to its current neutral outlook” Mohammed Ali
                Yasin, chief strategy officer at Al Dhabi Capital in Abu Dhabi,
                said in a text message: • There’s concern that the new tariffs
                will be borne by U.S. consumers as prices will increase
                accordingly, lifting inflation above the Federal Reserve’s
                normalized rate of 2% o “That may change the current stance of
                interest rates in the U.S. from hold-to-cut to become
                hold-to-raise by year end or early 2020, which means more
                stock-market volatility and negative pressures” 15 • “I really
                find the way Trump used his Tweets to manage the failure of the
                trade talks with China a failure in itself!” • “I believe it
                undermined his negotiation team and killed any chance of a
                possible compromise to be reached privately before making a
                public statement by those teams! It looked like he panicked and
                wanted to throw the blame on them rather take part of the
                responsibility!” Hasnain Malik, the Dubai-based head of equity
                strategy at Tellimer, said in an email: • “Progress in
                U.S.-China trade talks is always going to be partial and
                temporary because the clash of interests at stake are not easily
                reconciled and the two negotiating parties are not under urgent
                pressure to settle” • The set-back in negotiations will hurt
                global growth expectations and pressure emergingmarket assets •
                “For those economies with manufacturing integrated with China it
                is worse. But it also remains the case that rival manufacturing
                exporters to China, such as Bangladesh and Vietnam, should
                benefit, over time, from the redirection of purchasing orders
                and greater marginal capacity addition”. Raffaele Bertoni, head
                of debt-capital markets at Gulf Investment Corp. in Kuwait City,
                said in an email: • Investors who are exposed to emerging-market
                assets should protect their portfolio by switching from
                countries that are already suffering from significant inflation
                pressure and heavily dependent on foreign-currency debt —
                including the Philippines, Indonesia, Malaysia, India, Turkey,
                Brazil and Argentina — to countries where interest rates are
                already low and there’s more room for easing monetary policy to
                support growth, such as South Korea, Thailand and Mexico • U.S.
                Treasuries would be one of the few safe havens “still cheap in
                terms of real rates” • Sees upside for U.S. investment-grade
                corporate bonds while remaining more cautious on U.S. high-yield
                debt which is more correlated to the performance of equity
                markets • Expects the dollar, Japanese yen and Swiss franc to
                benefit from demand for haven assets
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hold;

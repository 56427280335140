import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Slider from "./images/slide-1.png";
import Sliderr from "./images/slide-2.png";
import Sliderrr from "./images/sldie-3.png";
import Sliderrrr from "./images/slide-4.jpg";

import { NavLink } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import AOS from 'aos';
import 'aos/dist/aos.css'


export default () => {
    useEffect(() => {
        AOS.init({ duration: 1500 })
    }, []);
    return (
        <div className='clip' >
            <div className="curved-slider-container" data-aos="fade-up">

                <Swiper
                    spaceBetween={70}
                    slidesPerView={5}
                    navigation
                    pagination={{ clickable: true }}
                    breakpoints={{
                        // When window width is >= 1024px
                        1024: {
                            slidesPerView: 5,
                        },
                        // When window width is >= 768px and < 1024px
                        768: {
                            slidesPerView: 3,
                        },
                        // When window width is < 768px
                        0: {
                            slidesPerView: 1,
                        },
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >


                    <SwiperSlide ><NavLink to="/Inside"> <img className='swip' src={Slider} alt="logo" /></NavLink>
                        <h2 className='portable'>IThEC nomination</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Them"> <img className='swip' src={Sliderr} alt="logo" /></NavLink>
                        <h2 className='portable'>Omega WM asset</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Mind"><img className='swip' src={Sliderrr} alt="logo" /></NavLink>
                        <h2 className='portable'>Mind The Chinese</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Euro"><img className='swip' src={Sliderrrr} alt="logo" /></NavLink>
                        <h2 className='portable'>European Markets</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Longer"><img className='swip' src={Slider} alt="logo" /></NavLink>
                        <h2 className='portable'>Opportunities 2020</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Beyond"><img className='swip' src={Sliderr} alt="logo" /></NavLink>
                        <h2 className='portable'>Beyond Consensus</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Prime"><img className='swip' src={Sliderrr} alt="logo" /></NavLink>
                        <h2 className='portable'> Private View Blog</h2>
                            
                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/World"><img className='swip' src={Sliderrrr} alt="logo" /></NavLink>
                    <h2 className='portable'> Investment Landscape</h2>
</SwiperSlide>

                    <SwiperSlide><NavLink to="/Didier"><img className='swip' src={Slider} alt="logo" /></NavLink>
                    <h2 className='portable'>New Member in  Team</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Stock"><img className='swip' src={Sliderr} alt="logo" /></NavLink>
                    <h2 className='portable'>OMEGA Stock Equities</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Latest"><img className='swip' src={Sliderrr} alt="logo" /></NavLink>
                    
                    <h2 className='portable'>OWM on Products</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Down"><img className='swip' src={Sliderrrr} alt="logo" /></NavLink>
                    <h2 className='portable'>Growth is Taking</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Geneva"><img className='swip' src={Slider} alt="logo" /></NavLink>
                    <h2 className='portable'>Omega Geneva</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Hold"><img className='swip' src={Sliderr} alt="logo" /></NavLink>
                    <h2 className='portable-1'>Hold Tight</h2>
</SwiperSlide>






                </Swiper>

            </div>
        </div>
    );
};

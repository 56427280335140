import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import Dop from "./images/do-1.png";
import Dopp from "./images/do-2.png";
import Doppp from "./images/do-3.png";

import Duret from "./Duret";
import AOS from "aos";
import "aos/dist/aos.css";

const Down = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <Duret /> */}
      <section className="section-1 pt-3">
        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div className="row robo flex-column-reverse flex-lg-row">
            <div className="col-md-6 ">
              <div className="nom">
                <img className="Ethic" src={Dop} alt="logo" />
                <img className="Ethic pt-5" src={Dopp} alt="logo" />
                <img className="Ethic pt-5" src={Doppp} alt="logo" />
              </div>
            </div>
            <div className=" col-md-6 pt-3">
              <h2 className="Along ">
                Growth is taking a dangerous downward turn
              </h2>
              .
              <p className="phillo-1 pt-4">
                Posted on September 19, 2019 by oecdecoscope by Laurence Boone,
                OECD Chief Economist For over 18 months, since the outbreak of
                trade hostilities, growth has been weakening, slowly but surely.
                In May 2018 the OECD, along with other organisations, was
                predicting global growth of around 4% for 2019, whereas our
                current forecasts are for growth of below 3%. In the first half
                of 2018, global investment was increasing at an annualised pace
                of nearly 5%, and trade over 4%. This year, the annualised
                growth rate of investment could slide to below 1%, with trade
                turning negative in the second quarter. Growth prospects have
                plummeted in the wake of trade and investment. An urgent
                response is required, failing which we run the risk of finding
                ourselves stuck in a long period of low growth, the brunt of
                which will be felt primarily by the most vulnerable. This is
                because the events of the last 18 months are not just a passing
                trend. The proliferation of tariffs and subsidies and the
                increasing unpredictability of trade policies have destroyed
                growth in international trade, triggering a sharp slowdown in
                industrial output and investments. When companies do not know
                what tomorrow will bring, they exercise their “wait-and-see
                option”. Given that an investment is a long-term commitment,
                they are waiting for this insidious trade war to settle down in
                order to know where to invest. However, when temporary
                uncertainty is recurrent and rooted, large amounts of
                investments are withheld, thereby affecting not just present day
                demand but also tomorrow’s growth potential and employment. The
                investment gap created by this situation will have a long-term
                and structural impact on growth, all the more so as it will take
                time to clarify the new trade policy environment. This is
                clearly exemplified in the digital sector, given how the fastest
                investor always has a strong edge. But it is also the case for
                infrastructures, which are essential for business development.
                And at present, in addition to the digital sector, there is a
                global and structural need for infrastructure investment of
                nearly 7 trillion dollars per year, taking into account the
                energy transition in addition to traditional investment
                requirements. Paradoxically, the investment gap is growing at a
                time when governments can obtain long-term financing at very
                low, even negative, rates. There is a therefore a danger of
                growth being bogged down for a long time. It is dangerous to use
                the good performance of the service sector as compared to the
                decline in industry as a justification for policy inaction given
                that the two are inextricably linked. It is equally risky to
                draw a distinction between countries with a large industrial
                sector and countries that are more service-based and therefore
                supposedly less at risk, given that integrated supply chains
                exist at both the regional and global level, and between
                services and industries.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Down;

import React, { useEffect } from "react";

import { NavLink } from "react-router-dom";
import Mask from "./images/Mask.png";
import web from "./images/Group-1.png";
import forest from "./images/group-2.png";
import passing from "./images/group-4.png";
import AOS from "aos";
import "aos/dist/aos.css";
import MetaTags from "./components/MetaTags";

const Mission = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <MetaTags
        title="Omega The Wealth Management Company"
        description="Maximize your wealth with Omega, the premier wealth management company. Our proven expertise and tailored solutions will secure your financial future."
      />

      <section className="section-1 pt-5" id="mission" data-aos="fade-up">
        {/* <p className="phillo text-center pt-5">Philosophy</p> */}
        <h1 className="Mission text-center ">Mission: Your Wealth!</h1>

        <div className="container-fluid robo-1 ">
          <div
            className="row robo flex-column-reverse flex-lg-row"
            data-aos="fade-up"
          >
            <div className=" col-md-6 my-auto">
              <h2 className="custom-dob  ">
                Customer <br />
                Profile
              </h2>
              <h2 className="custom-2  ">Customer Profile</h2>
              <p className="phillo-1 pt-4 text-justify">
                Finding the right partner regarding wealth is a decision as
                personal as it is financial. More than just expertise and
                services, it is above all his capacity to fathom your risk
                acceptancy. Our duty is to make sure the stakes, and in
                particular the risks, are understood by all parties. Our
                activity is exclusively bespoke based.
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="mask img-fluid "
                src={"./images/Mask.png"}
                alt="logo"
              />
            </div>
          </div>

          <div className="row robo " data-aos="fade-up">
            <div className="col-md-6">
              <img className="mask img-fluid" src={web} alt="logo" />
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="custom  ">Preserve</h2>
              <p className="phillo-1 pt-4 text-justify">
                Risk zero does not exist, even cash is subject to loss due to
                inflation. Basic protection should, to the least, be inflation
                compensating. Defensive choices are available, they keep
                volatility at bay but capital gains as well. We nonetheless
                always bear in mind that cash is king, it provides fuel to seize
                market opportunities when the herd is bearish.
              </p>
            </div>
          </div>

          <div
            className="row robo flex-column-reverse flex-lg-row"
            data-aos="fade-up"
          >
            <div className=" col-md-6 my-auto">
              <h2 className="custom  ">Grow </h2>
              <p className="phillo-1 pt-4 text-justify">
                Financial market tools available are far too easy access for the
                unqualified, leading to potential losses. Our mission is
                precisely to avoid such outcomes. For assets to have the
                necessary time frame to grow the asset base requires a clear
                view of the lifestyle, cash out-flows, projects of our
                customers. Growth cannot be at all costs; it must be scheduled,
                and investment choices made accordingly.
                <br />
                <br />
                There is no free lunch. Systematic profit increment requires
                time.
              </p>
            </div>
            <div className="col-md-6">
              <img className="mask-1 img-fluid" src={forest} alt="logo" />
            </div>
          </div>

          <div className="row robo" data-aos="fade-up">
            <div className="col-md-6">
              <img className="mask-3 img-fluid" src={passing} alt="logo" />
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="custom">Passing The Baton</h2>
              <p className="phillo-1 pt-4 text-justify">
                Wealth transmission is a long-term project; it allows to invest
                in dynamic and growth ventures to compound capital for future
                generations. Passing the baton is the ultimate strategic step of
                wealth involving its transfer beyond one’s existence. It
                requires vision, sense of continuity and planning capacities
                with services beyond the financial realm such as offshore and
                taxation friendly structures.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;

import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Slider from "./images/slide-1.png";
import Sliderr from "./images/slide-2.png";
import Sliderrr from "./images/sldie-3.png";
import Sliderrrr from "./images/slide-4.jpg";

import { NavLink } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import AOS from 'aos';
import 'aos/dist/aos.css'
import Video from "./videos/Art.mp4";



export default () => {
    useEffect(() => {
        AOS.init({ duration: 1500 })
    }, []);
    return (
        <div className='clip' >
            <div className="curved-slider-container" data-aos="fade-up">

                <Swiper
                    spaceBetween={70}
                    slidesPerView={5}
                    navigation
                    pagination={{ clickable: true }}
                    breakpoints={{
                        // When window width is >= 1024px
                        1024: {
                            slidesPerView: 5,
                        },
                        // When window width is >= 768px and < 1024px
                        768: {
                            slidesPerView: 3,
                        },
                        // When window width is < 768px
                        0: {
                            slidesPerView: 1,
                        },
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >


                    <SwiperSlide ><NavLink to="/Euro"> <img className='swip' src={Slider} alt="logo" />
                    <h2 className='portable'>European Markets</h2>
</NavLink>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Stock"> <img className='swip' src={Sliderr} alt="logo" />
                    <h2 className='portable'>OMEGA Stock Equities</h2>
</NavLink>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Tatel"><img className='swip' src={Sliderrrr} alt="logo" />
                    <h2 className='portable'>
OWM Chairman</h2>
</NavLink>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Squaw"><img className='swip' src={Slider} alt="logo" />
                    <h2 className='portable'>
                    SQUAWK BOX</h2>
                    </NavLink>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Longer"><img className='swip' src={Sliderr} alt="logo" />
                    <h2 className='portable'>
                    No longer Investors</h2></NavLink>

                    </SwiperSlide>
                






                </Swiper>

            </div>
        </div>
    );
};

import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import AOS from 'aos';
import 'aos/dist/aos.css'


const Indep = () => {
    useEffect(()=>{
        AOS.init({duration:1500})
            },[]);
    return (
        <>
            <section className="section-1 pt-3">


                <div className="container-fluid robo-1 "  data-aos="fade-up">
                    <div className="row robo flex-column-reverse flex-lg-row">
                        
                        <div className="col-md-6 ">
                            <div className="nom">
                            <img className="Ethic" src={Nomi} alt="logo" />
                            </div>
                        </div>
                        <div className=" col-md-6 my-auto">
                            <h2 className="Allocate-4  ">
                            “Omega WM has a different approach to thematic asset management, read to discover!”
                            </h2>

                            <p className="phillo-1 pt-4">
                            2030 Thematic – Independence and Discipline
                            </p>
                        </div>
                    </div>




                </div>
            </section>
        </>
    );
};

export default Indep;

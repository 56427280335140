import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Gap = () => {
    useEffect(()=>{
        AOS.init({duration:1500})
            },[]);
    return (
        <>
            <section className="section-1 pt-3">


                <div className="container-fluid robo-1 " data-aos="fade-up">
                    <div className="row robo flex-column-reverse flex-lg-row">
                        
                        <div className="col-md-6 ">
                            <div className="nom">
                            <img className="Ethic" src={Nomi} alt="logo" />
                            </div>
                        </div>
                        <div className=" col-md-6 my-auto">
                            <h2 className="Allocate-4 ">
                            Mind The Chinese Gap
                            </h2>

                            <p className="phillo-1 pt-4">
                            During our Investment Committee, we reviewed the state of financial markets and how adjustments to the
                             Stop & Go economies could develop in the different regions. We also focused on the latest developments in
                              China, as the Government is implementing a back-to-basics strategy based on sharing wealth, avoiding financial
                               exuberance, and a high concentration policy. As we enter the fourth quarter, investment positioning will be with 2022 in mind: we provide guidance to investors on how to navigate this new environment.
                            </p>
                        </div>
                    </div>




                </div>
            </section>
        </>
    );
};

export default Gap;

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavLink } from 'react-router-dom';

import 'swiper/css';
import Slider from "./images/slide-1.png";
import Sliderr from "./images/slide-2.png";
import Sliderrr from "./images/sldie-3.png";
import Sliderrrr from "./images/slide-4.jpg";
import slide5 from "./images/slide-5.jpeg";

import COIM from "./pdf/COIM(25-Jan-2023).pdf"
import COIMM from "./pdf/COIM-16-Mar-2023.pdf"
import COIMMM from "./pdf/COIM-04-May-2023.pdf"
import COIMMMM from "./pdf/COIM-26-Jun-2023.pdf"
import COIMMMMM from "./pdf/COIM-29-Sep-2022.pdf"
import COIS from "./pdf/COIM-16-Aug-2022.pdf"
import COISS from "./pdf/COIM-16-Jun-2022.pdf"
import COISSS from "./pdf/COIM-05.05.2022.pdf"
import CNBC from "./pdf/CNBC-SQUAWK.pdf"
import CNBCC from "./pdf/211004-Interview.pdf"
import CNBCCC from "./pdf/2030-Thematic.pdf"
import CNBCCCC from "./pdf/2021.09.20-Chinese-Wall.pdf"
import JBE from "./pdf/JBE-Opportunities.pdf"
import JBEE from "./pdf/Beyond-Consensus.pdf"
import JBEEE from "./pdf/Private-View-Blog.pdf"
import COIC from "./pdf/COIC-22-Feb-2024.pdf"

export default () => {
    return (
        <div className='clip'>
            <div className="curved-slider-container">

                <Swiper
                    spaceBetween={70}
                    slidesPerView={5}
                    navigation
                    pagination={{ clickable: true }}
                    breakpoints={{
                        // When window width is >= 1024px
                        1024: {
                            slidesPerView: 5,
                        },
                        // When window width is >= 768px and < 1024px
                        768: {
                            slidesPerView: 3,
                        },
                        // When window width is < 768px
                        0: {
                            slidesPerView: 1,
                        },
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >

                    <SwiperSlide >
                        <a href={COIC} target="_blank" rel="noopener noreferrer">
                            <img className='swip' src={slide5} alt="logo" />
                            <div className='purif'> <h2 className='portable'>COIM-22-Feb-2024</h2></div>
                        </a>
                    </SwiperSlide>

                    <SwiperSlide ><a href={COIM} target="_blank" rel="noopener noreferrer"> <img className='swip' src={Sliderrr} alt="logo" />

                        <div className='purif'> <h2 className='portable'>COIM-25-Jan-2023</h2></div>
                    </a></SwiperSlide>

                    <SwiperSlide><a href={COIMM} target="_blank" rel="noopener noreferrer"><img className='swip' src={Sliderrrr} alt="logo" />
                        <h2 className='portable'>COIM-16-Mar-2023</h2>
                    </a></SwiperSlide>

                    <SwiperSlide><a href={COIMMM} target="_blank" rel="noopener noreferrer"><img className='swip' src={Slider} alt="logo" />
                        <h2 className='portable'>COIM-04-May-2023</h2>
                    </a></SwiperSlide>

                    <SwiperSlide><a href={COIMMMM} target="_blank" rel="noopener noreferrer"><img className='swip' src={Sliderr} alt="logo" />
                        <h2 className='portable'>COIM-26-Jun-2023</h2>
                    </a></SwiperSlide>

                    <SwiperSlide><a href={COIMMMMM} target="_blank" rel="noopener noreferrer"><img className='swip' src={Sliderrr} alt="logo" /></a>
                        <h2 className='portable'>COIM-29-Sep-2022</h2>
                    </SwiperSlide>

                    <SwiperSlide><a href={COIS} target="_blank" rel="noopener noreferrer"><img className='swip' src={Sliderrrr} alt="logo" /></a>
                        <h2 className='portable'>COIM-16-Aug-2022</h2>
                    </SwiperSlide>

                    <SwiperSlide><a href={COISS} target="_blank" rel="noopener noreferrer"><img className='swip' src={Slider} alt="logo" /></a>
                        <h2 className='portable'>COIM-16-Jun-2022</h2>
                    </SwiperSlide>
                    <SwiperSlide><a href={COISSS} target="_blank" rel="noopener noreferrer"><img className='swip' src={Sliderr} alt="logo" /></a>
                        <h2 className='portable'>COIM-05.05.2022</h2>
                    </SwiperSlide>

                    <SwiperSlide><a href={CNBC} target="_blank" rel="noopener noreferrer"><img className='swip' src={Sliderrr} alt="logo" /></a>
                        <h2 className='portable'>CNBC-SQUAWK</h2>
                    </SwiperSlide>
                    <SwiperSlide><a href={CNBCC} target="_blank" rel="noopener noreferrer"><img className='swip' src={Sliderrrr} alt="logo" /></a>
                        <h2 className='portable'>211004-Interview</h2>
                    </SwiperSlide>
                    <SwiperSlide><a href={CNBCCC} target="_blank" rel="noopener noreferrer"><img className='swip' src={Slider} alt="logo" /></a>
                        <h2 className='portable'>2030-Thematic</h2>
                    </SwiperSlide>
                    <SwiperSlide><a href={CNBCCCC} target="_blank" rel="noopener noreferrer"><img className='swip' src={Sliderr} alt="logo" /></a>
                        <h2 className='portable'>2021.09.20-Chinese</h2>
                    </SwiperSlide>
                    <SwiperSlide><a href={JBE} target="_blank" rel="noopener noreferrer"><img className='swip' src={Sliderrr} alt="logo" /></a>
                        <h2 className='portable'>JBE-Opportunities</h2>
                    </SwiperSlide>

                    <SwiperSlide><a href={JBEE} target="_blank" rel="noopener noreferrer"><img className='swip' src={Sliderrrr} alt="logo" /></a>
                        <h2 className='portable'>Beyond-Consensus</h2>
                    </SwiperSlide>

                    <SwiperSlide><a href={JBEEE} target="_blank" rel="noopener noreferrer"><img className='swip' src={Slider} alt="logo" /></a>
                        <h2 className='portable'>Private-View-Blog</h2>
                    </SwiperSlide>

                    <SwiperSlide ><NavLink to="/Inside"> <img className='swip' src={Slider} alt="logo" /></NavLink>
                        <h2 className='portable'>IThEC nomination</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Them"> <img className='swip' src={Sliderr} alt="logo" /></NavLink>
                        <h2 className='portable'>Omega WM asset</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Mind"><img className='swip' src={Sliderrr} alt="logo" /></NavLink>
                        <h2 className='portable'>Mind The Chinese</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Euro"><img className='swip' src={Sliderrrr} alt="logo" /></NavLink>
                        <h2 className='portable'>European Markets</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Longer"><img className='swip' src={Slider} alt="logo" /></NavLink>
                        <h2 className='portable'>Opportunities 2020</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Beyond"><img className='swip' src={Sliderr} alt="logo" /></NavLink>
                        <h2 className='portable'>Beyond Consensus</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Prime"><img className='swip' src={Sliderrr} alt="logo" /></NavLink>
                        <h2 className='portable'> Private View Blog</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/World"><img className='swip' src={Sliderrrr} alt="logo" /></NavLink>
                        <h2 className='portable'> Investment Landscape</h2>
                    </SwiperSlide>

                    <SwiperSlide><NavLink to="/Didier"><img className='swip' src={Slider} alt="logo" /></NavLink>
                        <h2 className='portable'>New Member in  Team</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Stock"><img className='swip' src={Sliderr} alt="logo" /></NavLink>
                        <h2 className='portable'>OMEGA Stock Equities</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Latest"><img className='swip' src={Sliderrr} alt="logo" /></NavLink>

                        <h2 className='portable'>OWM on Products</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Down"><img className='swip' src={Sliderrrr} alt="logo" /></NavLink>
                        <h2 className='portable'>Growth is Taking</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Geneva"><img className='swip' src={Slider} alt="logo" /></NavLink>
                        <h2 className='portable'>Omega Geneva</h2>

                    </SwiperSlide>
                    <SwiperSlide><NavLink to="/Hold"><img className='swip' src={Sliderr} alt="logo" /></NavLink>
                        <h2 className='portable-1'>Hold Tight</h2>
                    </SwiperSlide>




                </Swiper>

            </div>
        </div>
    );
};

import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";

import Risks from "../../images/Managment-4.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Risk = () => {
  useEffect(()=>{
    AOS.init({duration:2000})
        },[]);
  return (
    <>
      <section className="section-1 ">
        <div
          className="container-fluid Magg-1111  " id="down"
          >
        
          <div className="row my-auto flex-column-reverse flex-lg-row" data-aos="fade-up">
            <div className="col-md-1 my-auto"></div>

            <div className="col-md-5   my-auto rangss pb-3" id="phone">
              <h2 className=" cut-1 ">03</h2>
              {/* <p className="cut-2">——— Services</p> */}
              <h2 className="cut-31">Risk Management</h2>
              <p className="cut-4 text-justify">
                Risk scrutiny and follow up is our strong point. As mentioned, processes have been implemented to track products. Several of Omega’s team members are quants, mathematicians or with an Artificial Intelligence (AI) background. Our choices rely on measurable KPIs. For sake of efficiency, we systematically seek to automate our daily tasks and free our mind for new opportunities and increase our knowledge on markets. In addition, portfolios always bear defensive features such as plain vanilla options, cash, gold, and/or alternatives. We strive to integrate quantitative measures of risk and forward looking sensitivity to reduce forecasting errors.
              </p>

              {/* <h4 className="cut-5">
                discover <br />
                more
              </h4> */}
              <a href="#disc" > <p className="cut-111">SCROL DOWN ——— </p></a>
            </div>

            <div className="col-md-5 speedo my-auto gx-0">
              <div>
                <img className="weal-111" src={Risks} alt="image"></img>

              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Risk;

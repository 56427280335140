import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import bor from "./images/board-1.png";
import borr from "./images/board-2.png";
import borrr from "./images/board-3.png";
import Adv from "./images/adv-1.jpg";
import Advv from "./images/adv-2.jpg";
import Advvv from "./images/adv-3.jpg";
import Mar from "./images/Luc.jpg";
import Marr from "./images/myriam.jpg";
import Marrr from "./images/nat.jpg";
import Pac from "./images/pacc.jpg";
import Pacc from "./images/paccc.jpg";
import Board from "./images/boarding.jpg";
import Adam from "./images/adam-czub.jpg";
import client1 from "./images/client-1.jpeg";
import client2 from "./images/client-2.jpeg";
import nazz from "./images/shagufta_naz.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const Baord = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="section-1 " id="board">
        <div className="container py-5 ">
          {/* <h1 className="Direct">Board of Directors<br /> & Management</h1> */}
          <div className="row" data-aos="fade-up">
            <div className="col-md-12">

              {/* Tabs */}
              <ul
                class="nav nav-tabs justify-content-center"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active Invent"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Board of Directors & Management
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link Invent"
                    id="client-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#client"
                    type="button"
                    role="tab"
                    aria-controls="client"
                    aria-selected="false"
                  >
                    Client Partners
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link Invent"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Investment Team
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link Invent"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Advisory Board
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link Invent"
                    id="contact-1-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact-1"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Corporate functions
                  </button>
                </li>
              </ul>

              <div class="tab-content" id="myTabContent">

                {/* Board Of Directors */}
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="container ">
                    <div className="row">

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={bor} alt="image" />
                            <h2 className="Did">Didier Duret</h2>
                            <p>
                              Chairman of the Board of Directors & Head of Investment Committee
                              {/* Chairman of the Board of Directors & Head of
                              Investment CommitFormer Chief Investment Officer
                              of ABNtee */}
                            </p>
                          </div>
                        </button>
                      </div>

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop1"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={borr} alt="image" />
                            <h2 className="Did">Hassine Ben Aissa</h2>
                            <p>
                              Managing Partner & Board of Director Member,
                              Member of the Executive Committee
                            </p>
                          </div>
                        </button>
                      </div>

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop2"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={Board} alt="image" />
                            <h2 className="Did">Rayan Houdrouge</h2>
                            <p>Board of Director Member </p>
                          </div>
                        </button>
                      </div>

                    </div>
                  </div>
                </div>

                {/* Client Partners */}
                <div class="tab-pane fade show" id="client" role="tabpanel" aria-labelledby="client-tab">
                  <div className="container ">
                    <div className="row">

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop13"
                        >
                          <div className=" boss">
                            <img src={client1} alt="image" height={330} />
                            <h2 className="Did">Ghassan Abed</h2>
                            <p>
                              Managing Director – Senior Private Banker
                            </p>
                          </div>
                        </button>
                      </div>

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop14"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={client2} alt="image" />
                            <h2 className="Did">Serghei Volosin</h2>
                            <p>
                              Private Banker
                            </p>
                          </div>
                        </button>
                      </div>

                    </div>
                  </div>
                </div>

                {/* Investment Team */}
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <div className="container ">
                    <div className="row">

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop4"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={Advvv} alt="image" />
                            <h2 className="Did">Alexis Chirinian</h2>
                            <p>
                              Co CEO, Senior Investment Advisor, Member of the
                              Executive Committee
                            </p>
                          </div>
                        </button>
                      </div>

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop6"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={Advv} alt="image" />
                            <h2 className="Did">Joël Guglietta</h2>
                            <p>Global Investment Solution Expert and Member of Investment Committee</p>
                          </div>
                        </button>
                      </div>

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop3"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={Adv} alt="image" />
                            <h2 className="Did">Emmanuel Rytzell</h2>
                            <p>Alternative Investment & Research Expert and Member of Investment Committee</p>
                          </div>
                        </button>
                      </div>

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop12"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={Adam} alt="image" />
                            <h2 className="Did">Adam Czub</h2>
                            <p>Managing Director - Senior Investment Manager</p>
                          </div>
                        </button>
                      </div>

                    </div>
                  </div>
                </div>

                {/* Advisory Board */}
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                  <div className="container ">
                    <div className="row">

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop7"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={Marr} alt="image" />
                            <h2 className="Did">Myriam Deblanc</h2>
                            <p>Structured Products Specialist</p>
                          </div>
                        </button>
                      </div>

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop8"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={Marrr} alt="image" />
                            <h2 className="Did">Natalia Belinskaia</h2>
                            <p>
                              Senior Relationship Manager & Trade Finance
                              Advisor
                            </p>
                          </div>
                        </button>
                      </div>

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop9"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={Mar} alt="image" />
                            <h2 className="Did">Luc Otten</h2>
                            <p>Advisor on Biotech Investments </p>
                          </div>
                        </button>
                      </div>

                    </div>
                  </div>
                </div>

                {/* Corporate function */}
                <div class="tab-pane fade" id="contact-1" role="tabpanel" aria-labelledby="contact-1-tab">
                  <div className="container ">
                    <div className="row">

                      {/* <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop10"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={Pac} alt="image" />
                            <h2 className="Did">Veronique Blavec</h2>
                            <p>
                              Director, Business Manager, Member of the
                              Executive Committee
                            </p>
                          </div>
                        </button>
                      </div> */}

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop15"
                        >
                          <div className=" boss">
                            <img src={nazz} alt="image" />
                            <h2 className="Did">SHAGUFTA NAZ</h2>
                            <p>Head of Business Operations & HR</p>
                          </div>
                        </button>
                      </div>

                      <div className="col-md-4">
                        <button
                          type="button"
                          class="btn "
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop11"
                        >
                          <div className=" boss">
                            <img className="manage-1" src={Pacc} alt="image" />
                            <h2 className="Did">Ying Zhang</h2>
                            <p>Junior Private Banker</p>
                          </div>
                        </button>
                      </div>

                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* <!-- Button trigger modal --> */}

          {/* Modal 1 */}
          <div
            class="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={bor} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Didier Duret</h2>
                        <p className="cut-4">Chairman of the Board of Directors & Head of Investment Committee{" "}</p>
                        <div className="pong">
                          <h6 className="coock">+30{" "}<span className="comm">{" "}years of experience in investments</span></h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Former Chief Investment Officer of ABN</li>
                            <li className="long">Former Member of the Board of Directors of Neuflize Life Insurance</li>
                            <li className="long">Lecturing in leadership and professional training at INSEAD and Cambridge Judge Business School of Management</li>
                            <li className="long">Regular speaker at CNBC, CNN, Bloomberg TV and major networks</li>
                            {/* <li className="long">Former Chief Investment Officer of ABN</li> */}
                            {/* <li className="long">   Certificate from Harvard Business School on Private Equity and VC</li> */}
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 2 */}
          <div
            class="modal fade"
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={borr} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Hassine Ben Aissa</h2>
                        <p className="cut-4">Managing Partner & Board of Director Member, Member of the Executive Committee{" "}</p>

                        <div className="pong">
                          <h6 className="coock">+18{" "} <span className="comm">{" "}years in the banking world</span></h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Former Banker at Barclays/Citi Bank/UBP</li>
                            <li className="long">ETH Zurich- MTEC Major in Finance</li>
                            <li className="long">EPF Lausanne – BSc System of Communication</li>
                            <li className="long">Certificate from MIT Sloan on AI</li>
                            <li className="long">{" "}Certificate from Harvard Business School on Private Equity and VC</li>
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 3 */}
          <div
            class="modal fade"
            id="staticBackdrop2"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={borrr} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Rayan Houdrouge</h2>
                        <p className="cut-4">Board of Director Member </p>

                        <div className="pong">
                          <h6 className="coock">+15{" "}<span className="comm">{" "}years of experience as Attorney at Law</span>
                          </h6>
                        </div>

                        <div>
                          <ul className="loof">
                            {/* <li className="long">Partner at Lenz and Staehelin</li> */}
                            <li className="long">Partner at Walder Wyss</li>
                            <li className="long">Member of the Geneva Bar Association and the Swiss Bar Association</li>
                            <li className="long">Master in Corporate Law at New York University</li>
                            {/* <li className="long">EPF Lausanne – BSc System of CommunicationMaster in Corporate Law at New York UniversityCertificate from MIT Sloan on AI</li> */}
                            {/* <li className="long">   Certificate from Harvard Business School on Private Equity and VC</li> */}
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 4 */}
          <div
            class="modal fade"
            id="staticBackdrop3"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={Adv} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Emmanuel Rytzell</h2>
                        <p className="cut-4">Alternative Investment & Research Expert and Member of Investment Committee</p>

                        <div className="pong">
                          {/* <h6 className="coock">+5{" "} <span className="comm">{" "} years of experience as Attorney at Law</span></h6> */}
                          <h6 className="comm" style={{ color: '#fff', marginTop: 15 }}>More than <span className="coock" style={{ margin: '0 6px' }}>30</span> years of experience in finance</h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Alternative Investments</li>
                            <li className="long">Former Relationship and Project Manager</li>
                            <li className="long">BNP-Paribas, Credit Suisse, BCV and AXA-Winterthur</li>
                            {/* <li className="long">Partner at Lenz and Staehelin</li> */}
                            {/* <li className="long">Member of the Geneva Bar Association and the Swiss Bar Association</li> */}
                            {/* <li className="long">EPF Lausanne – BSc System of CommunicationMaster in Corporate Law at New York UniversityCertificate from MIT Sloan on AI</li> */}
                            {/* <li className="long">   Certificate from Harvard Business School on Private Equity and VC</li> */}
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 5 */}
          <div
            class="modal fade"
            id="staticBackdrop4"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={Advvv} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Alexis Chirinian</h2>
                        <p className="cut-4">Co CEO, Senior Investment Advisor, Member of the Executive Committee{" "}</p>

                        <div className="pong">
                          <h6 className="coock">+25{" "}<span className="comm">{" "} years of experience in Private Banking</span>
                          </h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">16 years in charge of Investment Advisory Portfolios with a focus on Equity, Forex, and Derivatives</li>
                            <li className="long">Technical analyst specialist and use of a proprietary behavioral financial algorithm</li>
                            <li className="long">Former UBS, HSBC, BNP, Julius Baer</li>
                            {/* <li className="long">   Certificate from Harvard Business School on Private Equity and VC</li> */}
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 6 */}
          <div
            class="modal fade"
            id="staticBackdrop6"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={Advv} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Joël Guglietta</h2>
                        <p className="cut-4">Global Investment Solution Expert and Member of Investment Committee{" "}</p>

                        <div className="pong">
                          <h6 className="coock">+25{" "}<span className="comm">years of experience on Financial Investments</span></h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Former Senior Global Macro Quantitative Strategist at Dymon Asia and GAMA</li>
                            <li className="long">Previously, Managing Director, Quantitative Strategist and Portfolio Manager at a start-up hedge fund in Singapore</li>
                            <li className="long">Former economist for the French Ministry of Economy and Finance</li>
                            <li className="long">{" "} M.A. from the doctoral program ESSEC-IAE <br /> M.S. in Finance from Reims University <br /> Currently finishing his Phd in Mathematical Economics at GREQAM</li>
                            {/* <li className="long">{" "} M.A. from the doctoral program ESSEC-IAE M.S. in Finance from Reims University Currently finishing his Phd in Mathematical Economics at GREQAM </li> */}
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 7 */}
          <div
            class="modal fade"
            id="staticBackdrop7"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={Marr} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Myriam Deblanc</h2>
                        <p className="cut-4">Structured Products Specialist </p>

                        <div className="pong">
                          <h6 className="coock">{" "}+15{" "}<span className="comm">years of experience in Finance</span>
                          </h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Managing Partner & Founder at ProFin Partners</li>
                            <li className="long">Former Head of Private Banks Sales, Europe & Middle East at Royal Bank of Canada</li>
                            <li className="long">Former Senior Sales Structured Products cross assets to Asia, Middle East at Commerzbank</li>
                            <li className="long">{" "} Ex Auditor at Procter & Gamble </li>
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 8 */}
          <div
            class="modal fade"
            id="staticBackdrop8"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">                        <div className="moda">
                        <img className="popp" src={Marrr} alt="image" />
                      </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Natalia Belinskaia</h2>
                        <p className="cut-4">Senior Relationship Manager & Trade Finance Advisor{" "}</p>

                        <div className="pong">
                          <h6 className="coock">{" "}<span className="comm">CEO at Pandora Advisory SA{" "}</span></h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Former BNP Paribas Commodities Finance Manager</li>
                            {/* 
                              <li className="long">Former Head of Private Banks Sales, Europe & Middle East at Royal Bank of Canada</li>
                              <li className="long">Former Senior Sales Structured Products cross assets to Asia, Middle East at Commerzbank</li>
                              <li className="long"> Ex Auditor at Procter & Gamble</li> 
                            */}
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 9 */}
          <div
            class="modal fade"
            id="staticBackdrop9"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={Mar} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Luc Otten</h2>
                        <p className="cut-4">Advisor on Biotech Investments</p>

                        <div className="pong">
                          <h6 className="coock">{" "}<span className="comm">Advisor on Biotech Investments{" "}</span></h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Board member of Netris Pharma (France)</li>
                            <li className="long">
                              Previously worked as Principal for Vinci Capital (now Renaissance Management) which had portfolio companies in the biotech, diagnostic and medtech sectors (Addex, Anergis, Evolva, Immatics, Sensimed and Symetis).
                            </li>
                            <li className="long">Former financial analyst performing Equity research for the biotech and pharma industries at Helvea (now part of Baader Bank)</li>
                            <li className="long">{" "}Doctorate of Medicine – MD and a Doctorate of Philosophy – PhD at the University of Geneva (Switzerland)</li>
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 10 */}
          <div
            class="modal fade"
            id="staticBackdrop10"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header">button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={Pac} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Veronique Blavec</h2>
                        <p className="cut-4">Director, Business Manager, Member of the Executive Committee</p>

                        <div className="pong">
                          <h6 className="coock">+10{" "}<span className="comm">years cumulated experience in Private and Investment Banking Support functions.{" "}</span></h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Previous positions – CACIB (Singapore) and HSBC Private Bank Swiss (Geneva)</li>
                            {/* 
                              <li className="long">Previously worked as Principal for Vinci Capital (now Renaissance Management) which had portfolio companies in the biotech, diagnostic and medtech sectors (Addex, Anergis, Evolva, Immatics, Sensimed and Symetis).</li>
                              <li className="long">Former financial analyst performing Equity research for the biotech and pharma industries at Helvea (now part of Baader Bank) </li>
                              <li className="long"> Doctorate of Medicine – MD and a Doctorate of Philosophy – PhD at the University of Geneva (Switzerland) </li>
                             */}
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 11 */}
          <div
            class="modal fade"
            id="staticBackdrop11"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> </div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={Pacc} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Ying Zhang</h2>
                        <p className="cut-4">Junior Private Banker</p>

                        <div className="pong">
                          <h6 className="coock">+17 <span className="comm">years of Finance extensive experience. </span></h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Expertise in innovative investment strategies</li>
                            <li className="long">Previous roles at BNP-Paribas, Credit Suisse, BCV, and AXA-Winterthur</li>
                            <li className="long">Associated with CIFA and a member of SFAA</li>
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 12 */}
          <div
            class="modal fade"
            id="staticBackdrop12"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> </div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={Adam} alt="image" />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Adam Czub</h2>
                        <p className="cut-4">Managing Director - Senior Investment Manager</p>

                        <div className="pong">
                          <h6 className="coock">+20 <span className="comm">years of experience in ensuring the optimal performance and compliance of clients' investment profiles and strategies. </span></h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Former Chief Investment Officer at And Private Wealth SA in Geneva, and previously a Senior Investment Portfolio Manager at Bank Julius Baer & Co Ltd, also in Geneva.</li>
                            <li className="long">His illustrious career also encompasses roles at BNP Paribas (Suisse) SA, Union Bancaire Privée, ABN AMRO Bank NV and Citibank (Suisse)</li>
                            <li className="long">Master of Science in Quantitative Finance from the Swiss Federal Institute of Technology Zurich (ETHZ)</li>
                            <li className="long">An Expert Examiner for the Certified Wealth Management Advisor Certificate</li>
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 13 */}
          <div
            class="modal fade"
            id="staticBackdrop13"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> </div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={client1} alt="image" height={335} width={'80%'} />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Ghassan Abed</h2>
                        <p className="cut-4">Managing Director – Senior Private Banker</p>

                        <div className="pong">
                          <h6 className="coock">+27 <span className="comm">years of experience in the UAE Former Director of Global Private Wealth at Incred Global Wealth Limited Previous experience include roles at Standard Chartered Bank, Barclays Bank & Abu Dhabi Commercial Bank. </span></h6>
                        </div>

                        <div>
                          {/*   <ul className="loof">
                            <li className="long">Former Chief Investment Officer at And Private Wealth SA in Geneva, and previously a Senior Investment Portfolio Manager at Bank Julius Baer & Co Ltd, also in Geneva.</li>
                            <li className="long">His illustrious career also encompasses roles at BNP Paribas (Suisse) SA, Union Bancaire Privée, ABN AMRO Bank NV and Citibank (Suisse)</li>
                            <li className="long">Master of Science in Quantitative Finance from the Swiss Federal Institute of Technology Zurich (ETHZ)</li>
                            <li className="long">An Expert Examiner for the Certified Wealth Management Advisor Certificate</li>
                          </ul> */}

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 14 */}
          <div
            class="modal fade"
            id="staticBackdrop14"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> </div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={client2} alt="image" width={'100%'} />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">Serghei Volosin</h2>
                        {/* <p className="cut-4">Private Banker Certified Wealth Investment Manager (CISI Level 3) Former Senior Sales Manager at Star trader. Previous experience include roles at Global Trade Capital & Multibank Group Diploma in Engineering and Industrial Technologies</p> */}
                        <p className="cut-4">Private Banker</p>

                        {/* <div className="pong">
                          <h6 className="coock">+20 <span className="comm">years of experience in ensuring the optimal performance and compliance of clients' investment profiles and strategies. </span></h6>
                        </div> */}

                        <div>
                          <ul className="loof">
                            <li className="long">Certified Wealth Investment Manager (CISI Level 3)</li>
                            <li className="long">Former Senior Sales Manager at Star trader (MENA)</li>
                            <li className="long">Previous experience include roles at Global Trade Capital & Multibank Group (MENA)</li>
                            <li className="long">Diploma in Engineering and Industrial Technologies</li>
                          </ul>

                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal 15 */}
          <div
            class="modal fade"
            id="staticBackdrop15"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered  modal-xl">
              <div class="modal-content Abss">
                {/* <div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> </div> */}
                <div class="modal-body">
                  <div className="container mode py-4">
                    <div className="row">
                      <div className=" offset-md-1 col-md-5 my-auto">
                        <div className="moda">
                          <img className="popp" src={nazz} alt="image" width={'100%'} />
                        </div>
                      </div>
                      <div className="col-md-5 my-auto">
                        <p className="cut-2">Team</p>
                        <h2 className="Bena">SHAGUFTA NAZ</h2>
                        {/* <p className="cut-4">Private Banker Certified Wealth Investment Manager (CISI Level 3) Former Senior Sales Manager at Star trader. Previous experience include roles at Global Trade Capital & Multibank Group Diploma in Engineering and Industrial Technologies</p> */}
                        <p className="cut-4">Head of Business Operations & HR</p>

                        <div className="pong">
                          <h6 className="coock">+20 <span className="comm">years of expertise in HR & Business Operations. </span></h6>
                        </div>

                        <div>
                          <ul className="loof">
                            <li className="long">Former Head of HR at Union Bancaire Privée  (Middle East Limited)</li>
                            <li className="long">Former Assistant VP HR at Alphen Capital (ME) Limited & Bank Sarasin-Alpen (ME) Limited</li>
                          </ul>
                          <button
                            type="button"
                            className="nopee"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <h6 className="coock-1">🡸 Back</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default Baord;
import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Cop from "../../images/allo-7.png";
import Managee from "../../images/Managment-6.png";
import AOS from 'aos';
import 'aos/dist/aos.css'


const Coping = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);
    return (
        <>
            <section className="section-1 ">
                <div className="container-fluid tomm ">
                    <div className="row py-5 flex-column-reverse flex-lg-row" data-aos="fade-up">
                        <div className="col-md-1 my-auto"></div>
                        <div className="col-md-5 my-auto ">
                            <h2 className=" cut-1 ">05</h2>
                            {/* <p className="cut-2">——— Do you know</p> */}
                            <h2 className="manage-3" style={{fontSize:'4.6vh'}}>Coping with the Cycle of Market Emotions</h2>
                            <p className="cut-4 text-justify">
                                When speaking of risks, besides market volatility the main concern is human irrationality and feelings. To solve this, a portfolio manager neutralizes potential biases by implementing rigorous in-house processes.
                            </p>

                            {/* <h4 className="cut-5">
                                discover <br />
                                more
                            </h4> */}

                        </div>
                        <div className="col-md-4 speed  my-auto ">
                            <div>
                                <img className="manage-1" src={Cop} alt="image"></img>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Coping;

import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import remain from "../../images/wealth-5.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Remind = () => {

  const navigate = useNavigate()

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="section-1 py-5">
        <div
          className="container-fluid Magg-11 
         "
          id="value"
        >
          <div
            className="row my-auto flex-column-reverse flex-lg-row"
            data-aos="fade-up"
          >
            <div className="col-md-1 my-auto"></div>

            <div className="col-md-4   my-auto band ">
              <h2 className=" cut-11 ">04</h2>
              <p className="cut-12">Philosophy</p>
              <h2 className="cut-13">Values</h2>
              <p className="cut-14 text-justify">
                Values say what and who we are. Values are referential to that remind us of what really matters, how we should behave, identify
                what is core or noise.
              </p>

              {/* <h4 className="cut-5" style={{ cursor: 'pointer' }}><a onClick={() => navigate('/process')}>discover <br /> more</a></h4> */}

              <h4 className="cut-15" style={{ cursor: 'pointer' }}><a onClick={() => navigate('/values')}>discover <br />more</a></h4>
            </div>
            <div className="col-md-6  my-auto ">
              <img
                className="weal-11 img-fluid "
                src={remain}
                alt="image"
              ></img>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Remind;

import React from "react";
import Multi from "./Multi";
import Board from "./Board";
import Scanned from "./Scanned";
import MetaTags from "./components/MetaTags";

function Team() {
  return (
    <>

      <MetaTags
        title="Meet Omega Wealth Management Expert Team"
        description="Meet the expert team at Omega Wealth Management for personalized financial solutions. Trust our experienced advisors to secure your future."
      />

      {/* <Scanned /> */}
      <Multi />
      <Board />
    </>
  );
}

export default Team;

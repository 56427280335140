import React from "react";
import Duret from "./Duret";
import Nominate from "./Nominate";

function Inside() {
  return (
    <>
      {/* <Duret /> */}
      <Nominate />
    </>
  );
}

export default Inside;

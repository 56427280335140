import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import Word from "./images/world.png";
import Duret from "./Duret";
import AOS from "aos";
import "aos/dist/aos.css";

const World = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <Duret /> */}
      <section className="section-1 pt-3">
        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div className="row robo flex-column-reverse flex-lg-row">
            <div className="col-md-6 ">
              <div className="nomp">
                <img className="Ethic" src={Word} alt="logo" />
              </div>
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="Along ">News on the Investment Landscape:</h2>.
              <p className="phillo-1 pt-4">
                Didier Duret Chairman of the Board « Everybody knows » is one of
                my favorite Leonard Cohen’s song these days. It distils a
                necessary distance to the noise and fury while entering the last
                mile towards the US election 4th of November, a moment in the
                race when the rhetoric gets nasty and personal and bears little
                policy relevance. This pure power quest can easily distract us
                from the essential economic forces marching towards an economic
                recovery. Policy makers have made it loud and clear that
                interest rates are low for a long time (until 2023, dixit
                Federal Reserve Board) and that monetary and fiscal support
                programs are on duty call to counter depressionary forces caused
                by the C19. 2021 can become the year of a slow return to normal
                economic conditions. The quest for asset returns, financial
                liquidity and the forward-looking nature of financial markets
                can logically explain the gap with the current economic arduous
                times populations and governments are facing. The general
                dynamics remains constructive for risky assets based on the
                scenario outline at the bottom of this note, it has not changed
                since Spring. Every investor knows she/he has to diversify to
                mitigate adversity on its investment return. Nothing new under
                the sun. Textbook readymade rules or out of the cuff advice
                abounds on the forms and shapes of diversification. The
                generalities of diversification are sound albeit not all
                investors abide to their wisdom and discipline. Strategic asset
                allocation remains the anchor for driving 80% of the medium-term
                performance for the asset blocks. But the devil is in the
                details and portfolio adjustments or position building in Q4-
                2020 can be building stones for effective diversification. Here
                enters the judgment on tactical actions that could contribute to
                this medium-term goal. Reviewing crowded trades, contrarian
                views and safe haven positions for better diversification
                Practically, it is relevant to review crowded trades, contrarian
                alternatives and revisit safe haven ideas within each asset
                classes as here rest decisions with significance for a prudent
                exposure and sound diversification. In technical terms, crowded
                trades refer to the momentum supporting the winners of
                yesterday, contrarian views related to non-obvious
                opportunities, and safe havens are aimed at equilibrating
                portfolio risk. The C19 « flash economic depression » and the
                gigantic policy stimulation have changed what we can expect from
                these three categories. Crowded trades in megatech stocks may be
                also a recognition of a structural diffusion of technologies or
                changes in client demand and the exponential use of digital
                tools to support sales and access to clients. Contrarian ideas
                can be shots in the dark as they need more conviction and
                evidence in the recovery process (i.e. for the cyclical sectors)
                or a corporate repositioning in the competitive landscape. Safe
                haven positions also need to be revisited. Gold for instance is
                highly correlated with other risky assets. Its price is gyrating
                with the ebbs and flows of financial liquidity. Equities: hard
                to dismiss the medium performance engine Tech giants, pharma
                (due the race for a C19 vaccine) and work-from-home-stocks are
                crowded but necessary in portfolios: these compagnies have
                demonstrated their capacity to rebound after the end Summer
                correction. Buying future earnings growth is expensive and will
                continue to be so until the broad indexes earnings base is
                restored. The thematic of cybersecurity implies a heavy dose of
                tech exposure. Cyclicals such as energy, industrials, consumer
                discretionary and even financials may be opportunist buys after
                their appalling underperformance year-to-date. The rotation into
                sectors facing cyclical and structural headwinds will happen in
                sequences and probably over the full 2021. Asia equity markets
                have the potential to outperform as a cyclical exposure,
                considering China’s lead in the recovery. Dividend stocks or
                sectors such as utilities, and consumer staples will keep their
                defensive status in consolidation or correction phases. The C19
                shock is redefining corporate quality and the thematic of «
                governance angels » is the most impactful way to get involved
                into ESG investments. Bonds: New realities and remaining
                relevance Investment Grade (IG) markets are heavily crowded by
                public purchases. Single investors are in direct competition
                with central bank purchasing programs and large institutions.
                The natural erosion of the bond portfolio due to reimbursements
                is a steep challenge for portfolio managers to generate income.
                Credit risk is an « uncomfortable lunch » for the investor.
                Opportunities rest with specific niches in IG/ High Yield (HY)
                crossover strategies and programs. Non-standard bond proxies
                such as infrastructure and private debt may be alternatives for
                income generation, but illiquidity and selection risk are high
                and limit their access to qualify investors. With straight
                bonds, USD denominated EM bonds are islands above the sea with
                positive nominal yields. Government bonds with their negative or
                barely positive yields are expensive safe haven, to paraphrase
                again Leonard Cohen, « the dices are loaded » by central banks.
                Bonds yields may still enter in further negative territories
                should the world experience another recession. Investors can
                hold long bonds as insurance but at the expense of high duration
                risk. In absence of explicit burst of inflation, 10-year
                Treasury Note yields above 1% may be levels to build positions.
                FX and oil Euro remain crowded and it has lost the strength to
                break the 1.2 in USD terms, even after EU’s pivotal policy
                commitments over the Summer. We can infer that the USD will keep
                its primus inter pares status among major currencies. USD has
                safe haven features despite undeniably poor debt fundamentals.
                Chasing good’old safe haven by accumulating CHF around 1.10
                against Euro. Consider NOK and SEK as diversifying cyclical
                currencies. GBP below 1.3 against USD remains attractive and a
                diversifier despite the Brexit realities, Oil: world economy
                gathering momentum can revive oil demand (WTI price range 40 to
                70 USD/b). Only an aggravation of known risks or emergence of
                unknown risks could capsize the boat Risk in the months and
                years to come may come from a slippage of known risks, such as a
                tougher confrontation between the West and China, although
                analogies with the Cold War and the Thucydides trap (the
                emergence of China as challenging power to the US as Athens was
                to Sparta) may be only rhetorical. The factual economic
                interdependence between powers is the utmost difference and is
                limiting outright confrontation. A « confrontational coexistence
                » seems more appropriate with more strategic constraints for
                firms. Applying specific scoring in stock selection can mitigate
                this risk. Other known triggers such as higher C19 fatality
                rate, possibilities of a technical market corrections are
                largely discussed, analysed and largely build into the equity
                risk premia. Two tail risks may not be totally discounted,
                possibly because we prefer to deny them as we did with the
                epidemic… One is our dependence on digital technologies, and the
                risk is of a partial or a global « cyber sudden stop » which may
                be a worst blow than the C19 pandemic. Imaginative solutions to
                adapt with more technologies has increased complexity and
                security issues. The second tail risk rest in the erosion of
                market discipline to price risk. One should reflect on the role
                of bond markets to price properly government debt risk for
                instance. Money-for-free and central bank market activism have
                destroyed the capacity of the market vigilantes to attribute
                higher yield to highly indebted nations of firms. Are we facing
                a man-made systemic risk of a new nature? I am not totally sure
                G10 government would have embarked such massive fiscal stimulus
                to cope with the C19 should the borrowing rates would have been
                4 to 6% for governments. At a point in time, differentiation
                between good borrowers and bad borrowers could hit with a
                revenge, when the economic front improves (a distant possibility
                so far), then adjustment of central bank policies can ignite
                volatility and differentiation. Conclusions: With the hopes of
                an economic recovery and constructive financial conditions
                (medium volatility regime and the opportunity set), the equity
                bull run can mature and survive correction phases of 5% and be
                redistributed into lagging and less expensive segments. Passive
                world equity market instruments can provide « automatic
                diversification » as playing actively swift rotations is far
                from obvious. Active managers with a technology/ cyclical
                barbell could be preferred. Trading up quality in the core bond
                portfolio is wise with adjunction of selected crossover active
                strategies, EM exposure and UST as safe haven as yields
                eventually move up. Reminder of the scenario: incremental return
                to economic life is the underlying expectation behind the rally
                unfolding in risky assets, supported by the need of investors to
                find return for their capital. A new, multi-year cycle is
                unfoldingout of the C19 short depression with an acceleration of
                technological and social trends. Massive and affluent
                liquidityprovided by central banks to stay until economies
                recover their trend growth: 2% in US, 1.5% in EU and 5-6% in
                China. The carpet bombing of liquidity will insure proper
                functioning of financial and credit markets. The recovery is to
                be shouldered by the massive fiscalboost in the major countries
                to recover the loss in domestic demand. Potential tactical
                actions to be discussed based on portfolio updates.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default World;

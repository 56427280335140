import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import moment from 'moment-timezone';
import TimePicker from "./TimePicker";
import Clock from "./Clock";
import WorldClock from './WorldClock';
import AOS from 'aos';
import 'aos/dist/aos.css'

const Office = () => {

    const swisPhone = '+41 22 884 33 93';
    const dubaiPhone = '+41 22 884 33 93';

    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);

    return (
        <>
            <section className="cops" id="office" >
                <div className="container oop">
                    <h1 className="contact text-center" data-aos="fade-up">Contact Us</h1>
                </div>
            </section>
            <section className="section-2" >
                <div className="container">
                    <div className="row" data-aos="fade-up">
                        <div className="col-md-6 soft">
                            <div className="pick">

                                <WorldClock timeZone="Europe/Zurich" />
                                <br />
                                <h2 className="swiss">Swiss Office</h2>
                                {/* <p className="Geneva">Omega Wealth Management SA, Geneva, is under surveillance of a self-regulating<br /> body (Arif), themselves being supervised by the Swiss financial<br /> market authority (FINMA).</p> */}
                                <p className="Geneva">OMEGA Wealth Management SA, Geneva, is regulated by ARIF*, a Self-Regulated Organization (SRO) <br /> recognized by the Swiss Financial Market Supervisory Authority (FINMA)</p>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="dot">
                                        <a href={`tel:${swisPhone.replace(/\s/g, '')}`}>
                                            <p className="phone">Phone</p>
                                            <p className="number">{swisPhone}</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="dot">
                                        <p className="phone">Email</p>
                                        <p className="number" > info@omega.wm.com</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="dot">
                                        <a href='https://maps.app.goo.gl/7zmmvQr1L5u6DxJi8' target='_blank'>
                                            <p className="phone">Map</p>
                                            <p className="number" >View Location in Google Map</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 soft-1">
                            <div className="pick">

                                <WorldClock timeZone="Asia/Dubai" />
                                <br />
                                <h2 className="swiss">Dubai Office</h2>
                                <p className="Geneva">OMEGA Wealth Management Limited, Dubai,<br /> is regulated by the Dubai Financial Services Authority.</p>
                            </div>

                            <div className="row limi">
                                <div className="col-md-3">
                                    <div className="dot">
                                        <a href={`tel:${dubaiPhone.replace(/\s/g, '')}`}>
                                            <p className="phone">Phone</p>
                                            <p className="number">{dubaiPhone}</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="dot">
                                        <p className="phone">Email</p>
                                        <p className="number">info@omega.wm.com</p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="dot">
                                        <a href="https://maps.app.goo.gl/7WwxSH2vQrNc4Z1d7" target='_blank'>
                                            <p className="phone">Map</p>
                                            <p className="number" >View Location in Google Map</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    );
};

export default Office;

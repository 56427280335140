import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import windowSize from "react-window-size";
import Manage from "../../images/wealth-9.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Unique = ({ windowWidth }) => {

    const isMobile = windowWidth <= 768

    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);

    return (
        <>
            <section className="section-1 " id="unique">
                <div className="container-fluid Magg" style={!isMobile ? { height: '600px' } : {}}>
                    <div className="row py-5 flex-column-reverse flex-lg-row" data-aos="fade-up">
                        <div className="col-md-1 my-auto"></div>
                        <div className="col-md-6 my-auto ">
                            <h2 className=" cut-1 ">01</h2>
                            {/* <p className="cut-2">——— Services</p> */}
                            <h2 className="manage-3">Wealth <br /> Management:<br />
                                A Unique Investment
                                Proposition</h2>
                            <p className="cut-4 text-justify">
                                Wealth management is a blending of many know-hows. It spans from technical market execution issues to risk aversion psychology.
                                The vast array of services includes Asset management, financial planning, Risk management,
                                Advisory services, Discretionary mandates, and a full family office coverage.
                            </p>

                            {/* <h4 className="cut-5">
                                discover <br />
                                more
                            </h4> */}
                            <a href="#assue" > <p className="cut-6">SCROL DOWN ——— </p></a>
                        </div>
                        <div className="col-md-4 speed  my-auto ">
                            <div>
                                <img className="manage-1" src={Manage} alt="image"></img>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default windowSize(Unique);
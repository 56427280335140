import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Teat from "./images/team-1.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Multi = () => {
    useEffect(()=>{
        AOS.init({duration:2000})
            },[]);
    return (
        <>
            <section className="section-1 " id="multi">
                <div className="container-fluid tomm">
                    <div className="row py-5 flex-column-reverse flex-lg-row" data-aos="fade-up">
                        <div className="col-md-1 my-auto"></div>
                        <div className="col-md-5 my-auto ">
                            <h2 className=" cut-1 ">01</h2>
                            <p className="cut-2">Team</p>
                            <h2 className="Allocate">Our Team</h2>
                            <p className="cut-4" style={{textAlign:'justify'}}>
                            We are multicultural with various backgrounds (engineering, management, banking…). We think differently and challenge each other. When we suggest a solution, the subject has been duly scanned and discussed.                            </p>

                            {/* <h4 className="cut-5">
                                discover <br />
                                more
                            </h4> */}
                            <a href="#board">  <p className="cut-633">SCROL DOWN ——— </p></a>
                        </div>
                        <div className="col-md-5 speed  my-auto ">
                            <div>
                                <img className="manage-1" src={Teat} alt="image"></img>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
             
            
                </div>
            </section>
        </>
    );
};

export default Multi;

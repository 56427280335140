import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import leat from "./images/let1.jpg";
import leatt from "./images/let2.jpg";
import leattt from "./images/let3.jpg";
import leatttt from "./images/let4.jpg";

import Duret from "./Duret";
import AOS from "aos";
import "aos/dist/aos.css";

const Latest = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <Duret /> */}
      <section className="section-1 pt-3">
        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div className="row robo ">
            <div className="col-md-6 ">
              <div className="nom">
                <img className="Ethic" src={leat} alt="logo" />
              </div>
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="Along ">Latest news from OWM on products</h2>.
              <p className="phillo-1 pt-4">
                Omega Wealth Management (OWM) strives to identify alternative
                funds from standard stock and bond markets. Market returns have
                considerably diminished in the past years, hence OWM’s policy to
                diversify investment vehicles. Since inception of our investment
                policy, we found and invested in various funds to reap profits
                bearing four factors: Decorrelated solutions Niche proven
                alternatives Self-liquidating products Low volatility The funds
                introduced in the portfolios during 2019 were: Cairn Capstone
                Special Opportunity fund Carlisle Life Settlements Bootstrap
                Venture Debt Barak Structured Trade Finance fund JC Opportunity
                fund In 2020: TransAsia Asian Trade Finance fund A new comer:
                Transasia, Asia Trade finance fund Transasia, the latest comer
                is in the short-term commodity trade finance bracket. We chose
                this fund for several reasons: Historical depth: Fund created in
                2014 50 days tenor short cycle financing (Shortest cycle of all
                the funds managed by Transasia) Very stable monthly historical
                return with a YTD 6.28% in 2019 USD 500M AUM, 79% loan to value
                0.36% loss incurred versus total financing, very low losses The
                fund convinced us for the above reasons. It is also an
                opportunity to complete our commodity finance diversification in
                Asia, an area where our portfolios were not exposed so far.
                Furthermore, the background of the investment team, the variety
                of the various trading players (see below) and high visibility
                given the shortness of the cycles convinced us. We were already
                on the commodity bracket with Barak Structured Trade Finance
                fund, Transasia completed this niche. Liquidity is standard for
                this type of product with a monthly quote and 60-day notice.
                Originally, we had no access to this fund for it had been
                closed. One of our banking partners had a slot that we seized.
                Our network of investors and analysts confirmed the historical
                curriculum and excellent fund management references. Our
                consistent diversification policy, market analysis, banking
                network, market confirmation views and very low historical
                losses in the fund were all aligned to convince us to feed our
                portfolios with Transasia.
              </p>
            </div>
          </div>

          <div className="row robo flex-column-reverse flex-lg-row">
            <div className=" col-md-6 my-auto">
              {/* <h2 className="Along ">
                                Latest news from OWM on products
                            </h2> */}
              .
              <p className="phillo-1 pt-4">
                For the weeks to come, we shall be studying various impact
                investment products such as infrastructure finance, real estate
                equity participations in Europe and the US, Short term SME
                project financing in the UK, Swiss high-tech investment funds,
                Short term mid-size loans to southern US corps. More than ever,
                given market volatility and unchartered situations such as CovID
                19, our policy to diversify investments on spaces left by the
                bank retreat combined by capitalizing on investment managers who
                have proven their capacities in specific niches is, to our point
                of view, the track to follow. Our alternative product policy is
                a way to diversify and temper potential downside situation. OWM
                Market opinion The past week (Feb 4th week) turned out to be one
                of the quickest downhills since 2008. However, of 5 reasons
                explaining the situation, only1 is CovID19. Here are the points
                to consider….and the pronostic we believe will happen. Market
                devaluation is due to “in the nearer term…we believe the greater
                risk is that the impact of the coronavirus on earnings may well
                be underestimated in current stock prices”. This quote from
                Peter Oppenheimer (Goldman Sachs) was right before the market
                downslide of 10%. Since, the correction has taken place.
                Election perception: Uncertainty about the U.S. 2020
                presidential election’s outcome is also starting to drive
                markets, strategists and analysts argue. A number of them think
                that if Sen. Bernie Sanders, an independent from Vermont who
                characterizes himself as a democratic socialist, wins the
                Democratic presidential nomination, and possibly even the
                presidency, stocks would take a hit as he is perceived by some
                as an antibusiness candidate. High PE’s. Even before the market
                slump this week, the value of stocks has been viewed as rich.
                One measure of stock-market values showed that the S&P 500 index
                was trading at 18.9 times the weighted aggregate consensus
                forward earnings estimate among analysts polled by MarketWatch.
                That is up from 16.2 a year ago, and, aside from a brief point
                early in 2018, it is the highest forward price-to-earnings ratio
                for the benchmark index since May 2002. Said differently, the
                correction we have experienced was bound to happen. The bond
                market. Government bonds yields have been sliding steadily as
                investors seek havens, and thus drive up bond prices, amid
                doubts about global economic growth in the wake of the
                coronavirus outbreak. The 10-year Treasury note yield
                TMUBMUSD10Y, -5.52% fell to a record low below 1.24% on Thursday
                at one point. Recession fears. Bond investors fear that the
                coronavirus might result in a global economic slowdown that
                might wash up on U.S. shores as a full-fledged recession.
                MarketWatch economics writer Rex Nutting explained the potential
                for an uncontained outbreak of COVID-19 this way: “Much of the
                immediate economic impact of a pandemic can be traced to the
                efforts to contain it, rather than from the effects of the
                disease itself. As we attempt to quarantine those who might
                spread the disease, we shut down a lot of economic activity.”
              </p>
            </div>
            <div className="col-md-6 ">
              <div className="nom">
                <img className="Ethic" src={leatt} alt="logo" />
              </div>
            </div>
          </div>
          <div className="row robo ">
            <div className="col-md-6 ">
              <div className="nom">
                <img className="Ethic" src={leattt} alt="logo" />
              </div>
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="Along ">The coming future</h2>.
              <p className="phillo-1 pt-4">
                We believe the market has returned to its long term base, i.e.
                2750. It is clearly an opportunity to buy, particularly that 74%
                of stocks are below their 200 day moving average.
              </p>
            </div>
          </div>

          <div className="row robo flex-column-reverse flex-lg-row">
            <div className=" col-md-6 my-auto">
              {/* <h2 className="Along ">
                                Latest news from OWM on products
                            </h2> */}
              .
              <p className="phillo-1 pt-4">
                Central banks will provide liquidity, the bank of Japan has
                already done so. Investors have finally started to hedge (see
                above), a very good contrarian sign The bull market is not over.
                Long term indicators are not pointing to this so far. Despite
                the turmoil, we are still in acceptable levels with long term
                valorizations. Historically, we have seen similar (not
                identical!) situations with the SRAS in 2003 where markets
                rebounded once the fear had been digested. As mentioned in the
                opinion section above, we believe the correction has been
                synchronically fed by other factors than the CovID19. OWM is
                however fully conscious that statistics to be published in April
                2020 on first quarter results will most probably confirm very
                bad figures due to the quarantine effects. The rebound will take
                place at the end of the second quarter and full third quarters.
                It is estimated that 1% of world GDP growth will be swept by the
                present CovID 19 effects. Our diversification policy is the most
                efficient way to straddle the present agitation. Emmanuel
                Rytzell
              </p>
            </div>
            <div className="col-md-6 ">
              <div className="nom">
                <img className="Ethic" src={leatttt} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Latest;

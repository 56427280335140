import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "./images/logo.png";

const Footer = () => {
  return (
    <>
      <section className="foot">
        <div className="container-fluid floop pt-5 pb-2">
          <div className="row ">
            <div className="col-md-3">
              <img src={Logo} alt="logo" />
            </div>
            <div className="col-md-9">

              <ul className="feet">
                <li>
                  <a className="read" href="/">Mission Your Wealth</a>
                </li>
                <li>
                  <a className="read" href="/Portfolio" >Building your portfolio</a>
                </li>
                <li>
                  <a className="read" href="/Philosophy">Philosophy</a>
                </li>
                <li>
                  <a className="read" href="/Managment">Wealth Management</a>
                </li>
                <li>
                  <a className="read" href="/Team">Team</a>
                </li>
                <li>
                  <a className="read" href="/News">News</a>
                </li>
                <li>
                  <a className="read" href="/Contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row foom">
            <div className="col-md-9">
              <h2 className="fame">Connect With Us</h2>
              <input className="type" type="text" placeholder="Switzerland +41       ➜" />
            </div>
            <div className="col-md-3 roofi my-auto">
              <NavLink className="reading" to="https://www.linkedin.com/company/omega-wm/" target="_blank">LinkedIn</NavLink>

            </div>
          </div>
          <div className="row pt-3 cond">
            <div className="col-md-6">
              <p className="feel-1">©2023 – OMEGA Wealth Management
              </p>
            </div>
            <div className="col-md-6">
              <ul className="feet">
                <li>
                  <NavLink className="read-3" to="#">Terms & Condition</NavLink>
                </li>
                <li>
                  <NavLink className="read-3" to="#">Legal Notice</NavLink>
                </li>
                <li>
                  <NavLink className="read-3" to="#">Privacy & Policy</NavLink>
                </li>

              </ul>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;

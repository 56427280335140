import React from "react";
import { NavLink } from "react-router-dom";
import Mission from "./Mission";
import Road from "./Road";
import Process from "./Process";
import Values from "./Values";
import Custom from "./Custom";

const Home = () => {
  return (
    <>
      {/* <Custom /> */}
      <Mission />
      {/* <Road /> */}
      {/* <Process /> */}
      {/* <Values /> */}
    </>
  );
};

export default Home;

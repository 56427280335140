import React from "react";
import Unique from "./Unique";
import Asset from "./Asset";
import Risk from "./Risk";
import Discretionary from "./Discretionary";
import Family from "./Family";
import MetaTags from "../../components/MetaTags";

function Managment() {
  return (
    <>

      <MetaTags
        title="Explore Our Wealth Management Services"
        description="Maximize your financial potential with our expert wealth management services. Discover tailored solutions for a secure and prosperous future."
      />

      {/* <Coverage /> */}
      <Unique />
      <Asset />
      <Risk />
      <Discretionary />
      <Family />
    </>
  );
}

export default Managment;

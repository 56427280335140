import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import Duret from "./Duret";
import AOS from "aos";
import "aos/dist/aos.css";

const Stock = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <>
      {/* <Duret /> */}
      <section className="section-1 pt-3">
        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div className="row robo flex-column-reverse flex-lg-row">
            <div className="col-md-6 ">
              <div className="nom">
                <div className="pock">
                  <iframe
                    width="510"
                    height="406"
                    src="https://www.youtube.com/embed/VgrUsfge5FE"
                    title="OMEGA Stock Equities Valuation Method"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>{" "}
              </div>
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="Along ">OMEGA Stock Equities Valuation Method</h2>

              <p className="phillo-1 pt-4">
                Emmanuel Rytzell, Omega Wealth Management SA.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stock;

import React from "react";
import Allocation from "./Allocation";
import Essential from "./Essential";
import Time from "./Time";
import Moat from "./Moat";
import Coping from "./Coping";
import MetaTags from "../../components/MetaTags";

function portfolio() {
    return (
        <>
            <MetaTags
                title="Build Your Portfolio With Omega Wealth Management Company"
                description="Discover how Omega Wealth Management can help you build a diversified investment portfolio tailored to your financial goals and risk tolerance."
            />

            {/* <Centric /> */}
            <Allocation />
            <Essential />
            <Time />
            <Moat />
            <Coping />
        </>
    );
}

export default portfolio;

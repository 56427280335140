import React, { useEffect } from "react";
import { Form, NavLink } from "react-router-dom";
import sate from "./images/process-1.png";
import satell from "./images/process-2.png";
import satelli from "./images/process-3.png";
import satelliat from "./images/process-4.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Process = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="section-1 " data-aos="fade-up">
        <p className="phillo text-center pt-3">Philosophy</p>
        <h1 className="Mission text-center ">
          Processes: A fresh & <br />
          Rigorous Approach
        </h1>

        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div
            className="row robo flex-column-reverse flex-lg-row"
            data-aos="fade-up"
          >
            <div className=" col-md-6 my-auto">
              <h2 className="custom" style={{fontSize:'5.2vh'}}>
                A Multiple Perspectives Investment Committee
              </h2>

              <p className="phillo-1 pt-4 text-justify">
                Knowledge does not knock at one’s door. Curiosity is vital.
                Intelligence availability leads to a critical analysis of
                investment proposals. Hence, they are compared to similar deals
                and reviewed with a controversial look.
                <br /> <br /> Such an attitude derives from Omega’s people and
                structure. With a team from totally different origins and
                educational backgrounds, our diversity contributes to reduce
                risk. Omega’s Investments are not top-down based decisions, but
                a result of a serie of viewpoint confrontations. Additionally
                and aiming for an effective diversification, we have also
                developped an internal quant based portfolio baptised DAAO, i.e.
                Dynamic Asset Allocation by Omega.
              </p>
            </div>
            <div className="col-md-6">
              <img className="mask img-fluid" src={sate} alt="logo" />
            </div>
          </div>

          <div className="row robo " data-aos="fade-up">
            <div className="col-md-6 my-auto">
              <img className="mask img-fluid" src={satell} alt="logo" />
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="custom" style={{fontSize:'5vh'}}>
                The 4 M’s: Moat, Meaning, Management & Margin of safety
              </h2>
              <p className="phillo-1 pt-4 text-justify">
                Our single stock as well as PE investment opportunities must
                comply with the 4 Ms. <br /> <br />
                The companies must bear a “Moat” to fend off competition, have a
                “Meaning” to add true value to its customers, have good
                ‘’Management’’ i.e. people with ethics/profit sharing approach,
                and an acquisition price with a ‘’Margin of safety” to withstand
                a market reversal. Omega takes pride in sticking to rules and
                keeping at bay emotional decisions. Very few investment deals
                pass the selection thresholds.   The companies must bear a
                “Moat” to fend off competition, have a “Meaning” to add true
                value to its customers, have good ‘’Management’’ i.e. people
                with ethics/profit sharing approach, and an acquisition price
                with a ‘’Margin of safety” to withstand a market reversal. Omega
                takes pride in sticking to rules and keeping at bay emotional
                decisions. Very few investment deals pass the selection
                thresholds.
              </p>
            </div>
          </div>

          <div
            className="row robo flex-column-reverse flex-lg-row"
            data-aos="fade-up"
          >
            <div className=" col-md-6 my-auto">
              <h2 className="custom  ">Private equity (PE) selection</h2>
              <p className="phillo-1 pt-4 text-justify">
                Omega’s Private Equity inclusion is a key differentiator of its
                investment policy. To de-correlate portfolios from potential
                market drawdowns, the ongoing PE selection starts with thorough
                research, due diligence, technical overviews, and legal
                coverage. PE deals have little or no history, hence their
                projected cash flows must be challenged and recouped. The factor
                to focus on is Growth.
                <br />
                <br />
                PE deals are often late-stage pre-IPO offerings featuring a
                substantial Total Addressable Market (TAM) or specialized
                managed funds focusing on a specific sector.
              </p>
            </div>
            <div className="col-md-6">
              <img className="mask-1 img-fluid" src={satelli} alt="logo" />
            </div>
          </div>

          <div className="row robo" data-aos="fade-up">
            <div className="col-md-6 my-auto">
              <img className="mask-3  img-fluid  " src={satelliat} alt="logo" />
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="custom" style={{fontSize:'4.9vh'}}>
                Public markets: the “full planetary alignment”
              </h2>
              <p className="phillo-1 pt-3 text-justify">
                At Omega we consider a stake in a business as a long-term commitment, an investment and not as a speculative tool.
                To be considered by us as a financial vehicle, a corporation must prove its potential and predictability, both fundamentally and technically.
                Public markets require quarterly publications.
                The data is scrutinized, of which we extract fair, DCF and intrinsic values which should be lower than the market price
                . If this measurement is above, we discard the investment.
                {/* Our single stock as well as PE investment opportunities must
                comply with the 4 Ms.
                <br /> <br /> The companies must bear a “Moat” to fend off
                competition, have a “Meaning” to add true value to its
                customers, have good ‘’Management’’ i.e. people with
                ethics/profit sharing approach, and an acquisition price with a
                ‘’Margin of safety” to withstand a market reversal. Omega takes
                pride in sticking to rules and keeping at bay emotional
                decisions. Very few investment deals pass the selection
                thresholds. */}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Process;

import React, { useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import Allo from "../../images/Allo-1.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Allocation = () => {

    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);

    return (
        <>
            <section className="section-1 " id="allow" >
                <div className="container-fluid Magg  " >
                    <div className="row pt-5 flex-column-reverse flex-lg-row" data-aos="fade-up">
                        <div className="col-md-1 my-auto"></div>
                        <div className="col-md-5 my-auto ">
                            <h2 className=" cut-1 ">01</h2>
                            {/* <p className="cut-2">——— Do you know</p> */}
                            <h2 className="Allocate">Asset Allocation</h2>
                            <p className="cut-4 text-justify">
                                80% of a portfolio’s performance stems from its asset allocation, it consists in attributing investments across different asset classes.
                                What is less known is the necessity for greater decorrelation. Indeed, the classic 40/60 (Bond/ equity) portfolio does not offer historic protection any longer.
                                Indeed, volatility has substantially increased in the recent past and is not about to disappear.
                                Market troughs have revealed a stronger interdependence between assets which historically compensated for each other.
                                In this context, Omega strives for more diverse solutions to withstand this issue.
                            </p>

                            {/* <h4 className="cut-5" style={{ cursor: 'pointer' }}>
                                <a onClick={() => navigate('/portfolio-optimiser')}>portfolio <br /> optimiser</a>
                            </h4> */}
                            
                            {/* <h4 className="cut-5">
                                discover <br />
                                more
                            </h4> */}
                            <a href="#essentia"> <p className="cut-6">SCROL DOWN ——— </p></a>
                        </div>
                        <div className="col-md-5 speed  my-auto ">
                            <div>
                                <img className="manage-1" src={Allo} alt="image"></img>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Allocation;

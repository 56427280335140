import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import windowSize from "react-window-size";

import Logo from "./images/logo.png";
import navVideo1 from "../src/videos/nav1.mp4";
import navVideo2 from "../src/videos/nav2.mp4";
import navVideo3 from "../src/videos/nav3.mp4";
import navVideo4 from "../src/videos/nav4.mp4";
import navVideo5 from "../src/videos/nav5.mov";
import navVideo6 from "../src/videos/nav6.mp4";
import navVideo7 from "../src/videos/nav7.mp4";
import NavVideo from "./components/Vidoe";

// const index=0;
// const circumference=0;
// const pointCount=0;

// const calculateRadius =(index) =() =>{
//   let circlePath =document.getElementById('mask-circle');
//   let offset = 0;

// 	if (index !== 0) offset = (circumference / pointCount) * (pointCount - index);

// 	circlePath.style.strokeDashoffset = `${offset}px`;
//   console.log("offset");
//   return offset;
// }

const useRingNavigation = (pointCount, circleRadius, startAnimDelta) => {
  const circumference = Math.PI * circleRadius * 2;
  const circlePathRef = useRef(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const calculateOffset = (index) => {
    // console.log(index)
    let offset = 0;
    if (index !== 0)
      offset = (circumference / pointCount) * (pointCount - index);
    circlePathRef.current.style.strokeDashoffset = `${offset}px`;
  };

  const handleItemClick = (index) => {
    var px = 1024;
    // calculateRadius(4);
    console.log(index);

    if (index == 4) {
      px = 300;
    } else if (index == 5) {
      px = 165;
    } else if (index == 6) {
      px = 0;
    } else if (index == 3) {
      px = 450;
    } else if (index == 2) {
      px = 585;
    } else if (index == 1) {
      px = 730;
    } else if (index == 0) {
      px = 870;
    } else {
      px = 1024;
    }
    console.log(`handleItemClick ${px}px`);

    let circlePath = document.getElementById("mask-circle");
    circlePath.style.strokeDashoffset = `${px}px`;

    // px+300;
    setSelectedItemIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  useEffect(() => {
    let buffer = 500;
    let delay =
      1000 * (1 + pointCount / startAnimDelta - 1 / startAnimDelta) + buffer;

    // console.log(delay)
    let name = window.location.pathname;
    console.log(name);
    let test = "";
    switch (name) {
      case "/":
        test = "7";
        break;

      case "/News":
        test = "5";
        break;

      case "/Contact":
        test = "4";
        break;

      case "/Team":
        test = "3";
        break;

      case "/Managment":
        test = "2";
        break;

      case "/Philosophy":
        test = "1";
        break;

      case "/portfolio":
        test = "0";
        break;
    }
    setTimeout(() => handleItemClick(test), delay);
  }, [pointCount, startAnimDelta]);

  return { circlePathRef, selectedItemIndex, calculateOffset, handleItemClick };
};

const RingNav = ({ links, windowWidth }) => {

  const isMobile = windowWidth <= 768
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [pageName, setPageName] = React.useState(window.location.pathname);
  const [videoState, setVideoState] = React.useState(null);
  const videoRef = useRef();

  const videoChange = (pageName, video) => {
    setPageName(pageName);
    setVideoState(video)
  }

  const getPathnameVideoPair = (pathname) => {
    const videoPairs = {
      "/": ["Mission: Your Wealth", navVideo1],
      "/portfolio": ["Building your portfolio", navVideo2],
      "/Philosophy": ["Philosophy", navVideo3],
      "/Managment": ["Wealth Management", navVideo4],
      "/Team": ["Team", navVideo5],
      "/Contact": ["Contact", navVideo6],
      "/News": ["News", navVideo7],
    };
    return videoPairs[pathname] || [pathname];
  }

  useEffect(() => {
    var name = window.location.pathname;
    const [pageName, video] = getPathnameVideoPair(name);
    videoChange(pageName, video);
    if (name === "/") {
      videoRef.current?.load();
    }
  }, [window.location.pathname]);

  const pointCount = links.length;
  const circleRadius = 160;
  const startAnimDelta = 5;

  const { circlePathRef, selectedItemIndex, calculateOffset, handleItemClick } = useRingNavigation(pointCount, circleRadius, startAnimDelta);

  var setPageStateAndPopup = (path, value = null) => {
    console.log(value);

    // let name =window.location.path
    handleItemClick(value);

    setPageName(path);
    document.getElementById("responsive-menu").checked = false;
  };

  return (
    <>
      {/* <section style={{background:'red',height:'10%',width:'auto'}}> */}
      <section>
        <div className="position-relative w-100 h-100">

          <div className="video-container">
            <NavVideo video={videoState} />
          </div>

          <div style={isMobile ? { paddingBottom: "20%" } : {}}>
            {/* nav bar */}
            <nav>
              <div class="container ">
                <input id="responsive-menu" type="checkbox" />
                <label for="responsive-menu">
                  <a href="/">
                    <img className="log" src={Logo} alt="logo" />{" "}
                  </a>
                  <span id="menu-icon"></span>
                </label>
                <div id="overlay"></div>
                <ul>
                  {/* <li><a href="/">Do You Know</a></li> */}

                  <li>
                    <NavLink
                      to="/"
                      onClick={() => {
                        setPageStateAndPopup("/", "6");
                      }}
                    >
                      Mission: your wealth!
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/Portfolio"
                      onClick={() => {
                        setPageStateAndPopup("/Building your portfolio", "0");
                      }}
                    >
                      Building your portfolio
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/Philosophy"
                      onClick={() => setPageStateAndPopup("/Philosophy", "1")}
                    >
                      Philosophy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/Managment"
                      onClick={() =>
                        setPageStateAndPopup("/Wealth Management", "2")
                      }
                    >
                      wealth management
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/Team"
                      onClick={() => setPageStateAndPopup("/Team", "3")}
                    >
                      Team
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/Contact"
                      onClick={() => setPageStateAndPopup("/Contact", "4")}
                    >
                      Contact
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/News"
                      onClick={() => setPageStateAndPopup("/News", "5")}
                    >
                      News
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>

            <section style={isMobile ? { height: 270 } : {}}>
              <div>
                <div className="ring-main">
                  <div className="navigation-circle">
                    <div className="navigation-circle__inner">
                      <svg
                        className="navigation-circle-svg navigation-circle-svg--opaque"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 320 320"
                        style={{ enableBackground: "new 0 0 1090.8 1542.8" }}
                      >
                        <circle
                          cx="160"
                          cy="160"
                          r="158"
                          fill="none"
                          strokeWidth="0.5"
                          stroke="#ffffff"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          ref={circlePathRef}
                        ></circle>
                      </svg>

                      <svg
                        className="navigation-circle-svg navigation-circle-svg--mask"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 320 320"
                        style={{ enableBackground: "new 0 0 1090.8 1542.8" }}
                      >
                        <circle
                          id="mask-circle"
                          cx="160"
                          cy="160"
                          r="158"
                          fill="none"
                          strokeWidth="1"
                          stroke="#ffffff"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          style={{
                            strokeDasharray: isIOS ? "2800px" : "1005.3088px",
                          }}
                        ></circle>
                      </svg>
                      <div className="position">
                        <h1 style={isIOS ? { fontSize: '11px' } : isMobile ? { fontSize: '30px' } : {}} className="world" >{pageName == "/" ? "Do You Know" : pageName.replace("/", "")}</h1>
                      </div>
                      <ul className="navigation-circle__list">
                        {/* Each list item */}
                        {links?.map((link, index) => (
                          <li
                            key={index}
                            className={`navigation-circle-list-item ${window.location.pathname === link.path
                              ? "active"
                              : ""
                              }`}
                          >
                            <NavLink
                              to={link.path} // Adjust this path accordingly
                              className="navigation-circle-list-item__point"
                              onClick={() => {
                                handleItemClick(index);
                              }}
                              onMouseEnter={() => calculateOffset(index + 1)}
                              onMouseLeave={() =>
                                calculateOffset(selectedItemIndex + 1)
                              }
                            >
                              <div className="navigation-circle-list-item__meta">
                                <h3 className="navigation-circle-list-item__title" style={isIOS ? { fontSize: '10px' } : isMobile ? { fontSize: '30px' } : {}}>
                                  {link.label}
                                </h3>
                                {/* <h4 className="navigation-circle-list-item__subtitle">
                          
                          </h4> */}
                              </div>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <a href="#content" className="cut-44">
            {" "}
            <p className="">SCROL DOWN ——— </p>
          </a>
        </div>

        <div id="content" className="bg-white overflow-hidden">
          <Outlet />
        </div>
      </section>
    </>
  );
};

export default windowSize(RingNav);

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import road from "./images/road-1.png";
import road4 from "./images/road-4.png";
import web from "./images/Group-5.png";
import forest from "./images/group-2.png";
import satellite from "./images/group-3.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Road = () => {

  useEffect(() => {
    // const section = document.getElementById('sectionId');
    // if (section) {
    //   section.scrollIntoView({ behavior: "smooth" });
    // }
    AOS.init({ duration: 2000 });

  }, []);

  return (
    <>
      <section className="section-1 pt-3" data-aos="fade-up" id="sectionId">
        {/* <br/><br/> */}
        <p className="phillo text-center" >Philosophy</p>
        <h1 className="Mission text-center ">The Road Ahead</h1>

        <div className="container-fluid robo-1 ">
          <div className="row robo flex-column-reverse flex-lg-row " data-aos="fade-up">
            <div className=" col-md-6 my-auto">
              <h2 className="custom-dob  "> On the Cusp of  <br /> a New Era  </h2>
              <h2 className="custom-2">On the Cusp of a New Era</h2>
              <p className="phillo-1 pt-4 text-justify">
                The various studies read by the Omega team lead to a
                conclusion: since 2007 we have clearly entered a new era. “We
                are on the cusp of the fastest, deepest, most consequential
                transformation of human civilization in history, a
                transformation every bit as significant as the move from
                foraging to cities and agriculture 10,000 years ago” (Rethink X)
                <br /> <br />
                This disruptive context happens when technology changes converge
                synchronically in 5 fields: Food, Energy, Transportation,
                Information, Materials. They are one of the key roots from which
                our investment choices infer from.
              </p>
            </div>
            <div className="col-md-6">
              <img className="mask img-fluid" src={road} alt="logo" />
            </div>
          </div>

          <div className="row robo " data-aos="fade-up">
            <div className="col-md-6">
              <img className="mask img-fluid" src={web} alt="logo" />
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="custom" style={{fontSize:'5vh'}}>Dealing with Complexity</h2>
              <p className="phillo-1 pt-4 text-justify">
                Risk zero does not exist, even cash is subject to loss due to
                Financiers are primarily information aggregators anticipating
                potential profitable trends. To grasp them, we constantly recoup
                our views with specialists, read and seek macro and micro data
                to finally synthesize them into investment decisions. The
                challenge boils down to sorting noise from fundamentals. For
                efficiency purposes we remain open architects, both with
                in-house built algorithms or partnering with highly focused
                funds. Financial markets are the visible tip of the knowledge
                iceberg.
              </p>
            </div>
          </div>

          <div
            className="row robo flex-column-reverse flex-lg-row"
            data-aos="fade-up"
          >
            <div className=" col-md-6 my-auto">
              <h2 className="custom-dob  ">
                The YALE <br /> Endowment Fund
              </h2>
              <h2 className="custom-2">The YALE Endowment Fund</h2>
              <p className="phillo-1 pt-4 text-justify">
                The Yale endowment fund is one of Omega’s investments
                inspirations. The fund grew from $1 to $30 billion from 1985 to
                2020. Yale’s revolutionary Initial Policy Statement was to give
                its portfolio a greater allocation to alternative investments.
                Omega strives to get as close as possible to this view by
                identifying direct deals at medium to late pre-IPO stages.
              </p>
            </div>
            <div className="col-md-6">
              <img className="mask-1 img-fluid" src={road4} alt="logo" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Road;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import Duret from "./Duret";
import AOS from "aos";
import "aos/dist/aos.css";

const Longer = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <Duret /> */}
      <section className="section-1 pt-3">
        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div className="row robo flex-column-reverse flex-lg-row">
            <div className="col-md-6 ">
              <div className="nom">
                <img className="Ethic" src={Nomi} alt="logo" />
              </div>
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="Along ">
                Opportunities - No longer Investors, but Hunters!
              </h2>

              <p className="phillo-1 pt-4">
                Opportunities 2020 is almost over but its footprint will remain
                very present in 2021.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Longer;

import React from "react";
import Office from "./Office";
import Swiss from "./Swiss";
import Footer from "./Footer";
import MetaTags from "./components/MetaTags";

function Contact() {
  return (
    <>

      <MetaTags
        title="Contact Now - Omega Wealth Management"
        description="Get in touch with Omega Wealth Management now to access top-tier financial planning and investment expertise. Take control of your wealth journey starting today."
      />

      {/* <Swiss /> */}
      <Office />
      <Footer />
    </>
  );
}

export default Contact;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Esse from "../../images/allo-2.png";
import Essee from "../../images//allo-3.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Essential = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="section-1 pt-5 responsiveClass" data-aos="fade-up">
        <div className="container-fluid Magg-1" id="essentia">
          <div className="row my-auto">
            {/* <div className="col-md-1 my-auto"></div> */}
            <div className="offset-md-1 col-md-3 speed  my-auto ">
              <div>
                <img className="weal-11" src={Esse} alt="image"></img>
              </div>
            </div>
            <div className="col-md-4 my-auto rangs text-justify">
              <h2 className=" cut-11 ">02</h2>
              <p className="cut-12">Services</p>
              <h2 className="Allocate-4" style={{fontSize:"5.8vh"}}>Portfolio Diversification</h2>
              <p className="cut-14 text-justify">
                Portfolio diversification is essential to mitigate limited human
                capacity in predicting the future and its financial
                implications. More than ever, because of high frequency trading,
                influence of derivatives, market complexity and online
                speculation, hedging is more expensive, thus compelling
                portfolio managers to set up defensive strategies which can be
                complex and limited in their efficiency. Appropriate
                diversification is  therefore the most efficient means of
                defense. Just as important, when building portfolios we take
                into consideration a client’s risk aversion and history. Hence,
                diversified portfolios are compromises between a client’s constraints
                and risk/return opportunities.
              </p>

              {/* <h4 className="cut-15">
                discover <br />
                more
              </h4> */}
            </div>
            <div className="col-md-4 speed-4">
              <div className="my-5">
                <img className="asset" src={Essee} alt="image" />
                <a href="#time">
                  {" "}
                  <p className="cut-16">SCROL DOWN ——— </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default Essential;

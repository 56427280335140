import React from "react";
import Duret from "./Duret";
import Nominate from "./Nominate";
import Indep from "./Indep";

function Them() {
  return (
    <>
      {/* <Duret /> */}
      <Indep />
    </>
  );
}

export default Them;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import Duret from "./Duret";
import AOS from "aos";
import "aos/dist/aos.css";

const Beyond = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <Duret /> */}
      <section className="section-1 pt-3">
        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div className="row robo flex-column-reverse flex-lg-row">
            <div className="col-md-6 ">
              <div className="nom">
                <img className="Ethic" src={Nomi} alt="logo" />
              </div>
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="Along ">2021 - Beyond consensus</h2>
              Opportunities 2020 is almost over but its footprint will remain
              very present in 2021.
              <p className="phillo-1 pt-4">
                Beyond The Consensus The year 2021 will start with the legacies
                of 2020 at least in the beginning. Our Strategic Investment
                Advisor Didier DURET analyzes hereafter what one can expect.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Beyond;

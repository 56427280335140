import React from "react";
import Duret from "./Duret";
import Gap from "./Gap";

function Mind() {
  return (
    <>
      {/* <Duret /> */}
      <Gap />
    </>
  );
}

export default Mind;

import React, { useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import Assets from "../../images/Managment-2.png";
import Assetss from "../../images/Managment-3.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Asset = () => {

    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);

    return (
        <>
            <section className="section-1 pt-5">
                <div className="container-fluid Magg-1  " id="assue" data-aos="fade-up">
                    <div className="row my-auto">
                        {/* <div className="col-md-1 my-auto"></div> */}
                        <div className="offset-md-1 col-md-3 speed  my-auto ">
                            <div>
                                <img className="weal-11" src={Assets} alt="image"></img>
                            </div>
                        </div>
                        <div className="col-md-4 my-auto rangs">
                            <h2 className=" cut-11 ">02</h2>
                            {/* <p className="cut-12">——— Services</p> */}
                            <h2 className="Asset-13">Asset Management</h2>
                            <p className="cut-14 text-justify">
                                Our approach is centered on an adaptative and prudential asset allocation process which differs from most of our
                                competitors. Indeed, they bear classical asset allocation techniques which are essentially built for fair weather. Omega’s
                                portfolio key modifications are team decisions duly framed by an investment committee. An in-house CRM keeps track of all deals. Best-in-class executions are sourced from top-of-the-notch partnering banks. A key added value in the diversification
                                comes from our alternative investments and in-house quant-based solutions.
                            </p>

                            {/* <h4 className="cut-15" style={{ cursor: 'pointer' }}>
                                <a onClick={() => navigate('/portfolio-optimiser')}>portfolio <br /> optimiser</a>
                            </h4> */}

                            {/* <h4 className="cut-15">discover <br />more</h4> */}
                        </div>
                        <div className="col-md-4 speed-1   ">
                            <div>
                                <img className="asset" src={Assetss} alt="image"></img>
                                <a href="#down"><p className="cut-16">SCROLl DOWN ——— </p></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Asset;

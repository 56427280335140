import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import Cap from "./images/board-1.png";
import Duret from "./Duret";
import AOS from "aos";
import "aos/dist/aos.css";

const Prime = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      {/* <Duret /> */}
      <section className="section-1 pt-3">
        <div className="container-fluid robo-1 " data-aos="fade-up">
          <div className="row robo flex-column-reverse flex-lg-row">
            <div className="col-md-6 ">
              <div className="nom">
                <img className="Ethic" src={Cap} alt="logo" />
              </div>
            </div>
            <div className=" col-md-6 my-auto">
              <h2 className="Along ">New Member in Our Team - Didier Duret</h2>.
              <p className="phillo-1 pt-4">
                We would like to introduce to you our latest team member, Mr.
                Didier Duret as Strategic Development Advisor. As Senior
                Strategic advisor, he will provide on a regular basis an
                analysis and opinion on the state of the economy, financial
                markets in general and macro current events. Didier Duret will
                work closely with the rest of our team to identify financial
                trends and investment opportunities. His initial approach will
                be a broad base for the investment committee to work on. His
                substantial career path makes him capable of understanding
                macro-economic aggregates to project economic evolutions, profit
                potential and use this data to suggest markets trends. With 33
                years of experience in investments of which top tier banks such
                as ABN Amro, UBS and Paribas Asset Management, we seize here the
                opportunity to highlight some key points of his career. Global
                Chief Investment Officier (CIO) of ABN Amro Private Banking from
                2003 to January 2019, he has chaired the Global Investment
                Committee since its inception in 2003 with oversight on USD 200
                billion in all asset classes. In addition, he is NED at of
                Neuflize Life Insurance Board of Directors since 2015 and was
                member of ABN AMRO Investment Solutions Supervisory Board from
                2010 to December 2018. He has also worked for the IMF.
                Additionally, Didier provides lectures in leadership and
                professional training at INSEAD and acts as exam judge at the
                Cambridge’s Business School of Management as well as in areas of
                investment decision practice and governance. Likewise, he is a
                regular speaker at CNBC, CNN, Bloomberg TV and major networks.
                Regarding his education, he owns an INSEAD International
                Directors Program (IDP-C) certification. In addition he has a
                Master degree in Economics from Grenoble University and from the
                Institut d’Etudes Politiques. Furthermore he post-graduated in
                International Finance from the “Ecole des Hautes Etudes
                Commerciales” (HEC) in Montreal and from INSEAD. It is our
                pleasure to announce that Didier Duret will add value to Omega
                Wealth Management SA investment strategy decisions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Prime;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import Tone from "../../images/allocation.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Time = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="section-1 pt-5">
        <div className="container-fluid Magg-111 " id="time">
          <div
            className="row my-auto flex-column-reverse flex-lg-row "
            data-aos="fade-up"
          >
            <div className="col-md-1 my-auto"></div>

            <div className="col-md-5   my-auto rangss pt-5">
              <h2 className=" cut-1 ">03</h2>
              <p className="cut-2">Services</p>
              <h2 className="cut-31">Time, Not Timing</h2>
              <p className="cut-4 "> “I won’t own this business for ten minutes unless I’m willing to own it for ten years.”<br /> W. Buffett
              </p>
              <p className="cut-4 text-justify">
                Investing is a long-haul commitment and dedication to
                allow multiples to compound overtime. In this quest, one logically
                seeks to avoid extreme peaks and troughs to surf the inherent
                market volatility. However, looking at data reverting back to
                1930, empirical observation demonstrates that should an investor
                miss the S&P 500′s 10 best days each decade, the total return
                would be nil (till 2020). Conversely, an investor who held
                steady through the various business cycles for the same period,
                the return would have been 17,715%. Portfolio outcomes depend on
                very few critical days that cannot be predicted, time is key, not timing.{" "}
              </p>

              {/* <h4 className="cut-5">
                discover <br />
                more
              </h4> */}
              <a href="#moat">
                {" "}
                <p className="cut-64">SCROL DOWN ——— </p>
              </a>
            </div>

            <div className="col-md-1 my-auto"></div>

            <div className="col-md-4 speedo" style={{ position: 'relative', left: '7.9%' }}>
              <img className="weal-111" style={{ height: '93.1%' }} src={Tone} alt="image"></img>
              {/* <p className="cut-16">SCROL DOWN ——— </p> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Time;

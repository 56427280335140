import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Moaat from "../../images/allo-6.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Moat = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);
    return (
        <>
            <section className="section-1 pb-5 " data-aos="fade-up">
                <div
                    className="container-fluid Magg-11 " id="moat"

                >
                    <div className="row my-auto">
                        <div className="col-md-1 my-auto"></div>
                        <div className="col-md-5 my-auto ">
                            <img className="weal-11" src={Moaat} alt="image"></img>
                        </div>

                        <div className="col-md-5  my-auto band">
                            <h2 className=" cut-11 ">04</h2>
                            {/* <p className="cut-12">——— Do you know</p> */}
                            <h2 className="cut-13">“MOAT”,
                                The Investor’s Secret</h2>
                            <p className="cut-14 text-justify">
                                “A good business is like a strong castle with a deep moat around it. I want sharks in the moat.
                                I want it to be untouchable. “ – Warren Buffett                            </p>
                            <p className="cut-14 text-justify">
                                Initially identified by value investors, companies that have “Moats” are entities that thrive long term. Their unique defensive positioning is based on a “competitive advantage” vis a vis their peers, resulting in greater and more regular profits. The “Moats” can be found within 5 categories: Intangible assets, cost advantages, switching costs, network effects, efficient scale.
                                The epitome in this field is for a company to cumulate several moats.                          </p>

                            {/* <h4 className="cut-15">
                                discover <br />
                                more
                            </h4> */}
                            {/* <a href="#time">  <p className="cut-69">SCROL DOWN ——— </p></a> */}

                        </div>


                    </div>
                </div>
            </section>
        </>
    );
};

export default Moat;

import React from "react";
import Wealth from "./Wealth";
import Ahead from "./Ahead";
import Rigorous from "./Rigorous";
import Remind from "./Remind";
import MetaTags from "../../components/MetaTags";

function Philosophy() {
  return (
    <>

      <MetaTags
        title="Unlocking Prosperity: The Omega Wealth Management Philosophy"
        description="Learn how Omega Wealth Management's philosophy can unlock prosperity for you. Gain insights into managing your wealth and achieving financial success with our innovative approach."
      />

      {/* <Aversion /> */}
      <Wealth />
      <Ahead />
      <Rigorous />
      <Remind />
    </>
  );
}

export default Philosophy;

import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';

const CustomCookieConsent = () => {
  const [isCookiesAccepted, setCookiesAccepted] = useState(true);

  const handleDeclineCookies = () => {
    // Perform necessary actions to handle the declined cookies (e.g., storing user preferences)
    setCookiesAccepted(false);
  };

  if (!isCookiesAccepted) {
    return null; // You might decide to hide the entire cookie banner after it's declined.
  }

  return (
    <CookieConsent
      debug={false}
      location="bottom"
      style={{ background: '#000000d4' }}
      buttonStyle={{
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontFamily: 'Wensley',
        fontSize: '17px',
      }}
      buttonText="Yes, I accept Cookies"
    >
      <div>
        This website uses cookies in order to offer you the most relevant<br /> information. Please accept cookies for optimal
        performance.
        <span onClick={handleDeclineCookies} style={{ marginLeft: '10px', cursor: 'pointer', color: '#ffffff', textAlign: 'end' }}>
          &#10006; {/* This is the "X" symbol representing the cross */}
        </span>
      </div>

    </CookieConsent>
  );
};

export default CustomCookieConsent;

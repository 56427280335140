import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Nomi from "./images/nomi.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Road = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);
    return (
        <>
            <section className="section-1 pt-3">


                <div className="container-fluid robo-1 " data-aos="fade-up">
                    <div className="row robo flex-column-reverse flex-lg-row">

                        <div className="col-md-6 ">
                            <div className="nom">
                                <img className="Ethic" src={Nomi} alt="logo" />
                            </div>
                        </div>
                        <div className=" col-md-6 my-auto">
                            <h2 className="Allocate-4 ">
                                iThEC nomination announcement for Omega Chairman Didier Duret
                            </h2>

                            <p className="phillo-1 pt-4 text-justify">
                                We are pleased to announce than Omega Wealth Management SA, Didier Duret has been accepted as member of the iThEC International Thorium Energy Committee (iThEC) an offspring of the CERN, Geneva, whose aim is to promote the development of Thorium based nuclear energy. At stake is the future of safe and cheap nuclear energy. Please look at the site to familiarize with this disruptive development based on fundamental physics than will lead to a new ecosystem in the decades to come.
                            </p>
                        </div>
                    </div>




                </div>
            </section>
        </>
    );
};

export default Road;

import React from "react";
import "./App.css";

import Home from "./Home1";
import { Switch, Route, BrowserRouter, Router, Routes } from "react-router-dom";
import RingNav from "./RingNav";
import Portfolio from "./pages/portfolio";
import Philosophy from "./pages/philosophy";
import Team from "./Team";
import Contact from "./Contact";
import News from "./News";
import Inside from "./Inside";
import Footer from "./Footer";
import CookieConsent from "react-cookie-consent";
import CustomCookieConsent from "./CustomCookieConsent";
import Them from "./Them";
import Mind from "./Mind";
import Euro from "./Euro";
import Longer from "./longer";
import Beyond from "./Beyond";
import Prime from "./Prime";
import World from "./World";
import Didier from "./Didier";
import Stock from "./Stock";
import Latest from "./Latest";
import Down from "./Down";
import Geneva from "./Geneva";
import Hold from "./Hold";
import Tatel from "./Tatel";
import Squaw from "./Squaw";
import Road from "./Road";
import Process from "./Process";
import Values from "./Values";
import Managment from "./pages/management";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <RingNav
                links={[
                  { path: "/portfolio", label: "Building your portfolio" },
                  { path: "/Philosophy", label: "Philosophy" },
                  { path: "/Managment", label: "wealth management" },
                  { path: "/Contact", label: "Contact" },
                  { path: "/News", label: "News" },
                  { path: "/Team", label: "Team" },
                  { path: "/", label: "Misson: your wealth!" },

                  // Add more links as needed
                ]}
              />
            }
          >
            <Route path="/" element={<Home />} />
            <Route path="/Portfolio" element={<Portfolio />} />
            <Route path="/Philosophy" element={<Philosophy />} />
            <Route path="/Managment" element={<Managment />} />
            <Route path="/Team" element={<Team />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/News" element={<News />} />
            <Route path="/Inside" element={<Inside />} />
            <Route path="/Them" element={<Them />} />
            <Route path="/Mind" element={<Mind />} />
            <Route path="/Euro" element={<Euro />} />
            <Route path="/Longer" element={<Longer />} />
            <Route path="/Beyond" element={<Beyond />} />
            <Route path="/Prime" element={<Prime />} />
            <Route path="/World" element={<World />} />
            <Route path="/Didier" element={<Didier />} />
            <Route path="/Stock" element={<Stock />} />
            <Route path="/Latest" element={<Latest />} />
            <Route path="/Down" element={<Down />} />
            <Route path="/Geneva" element={<Geneva />} />
            <Route path="/Hold" element={<Hold />} />
            <Route path="/Tatel" element={<Tatel />} />
            <Route path="/Squaw" element={<Squaw />} />


            <Route path="/road-ahead" element={<Road />} />
            <Route path="/process" element={<Process />} />
            <Route path="/values" element={<Values />} />

            {/* Baki routes isi tarah yahan add kartay rahna */}
            <Route path="*" element={<h1>404</h1>} />
          </Route>


        </Routes>
      </BrowserRouter>

      {/* <CookieConsent debug={true}
     location="bottom"
     style={{background:'#000000'}}
     buttonStyle={{color:'#ffffff', backgroundColor:'transparent', fontFamily:'Wensley', fontSize:'17px' }}
     buttonText="Yes, I accept Cookies">
       This website uses cookies in order to offer you the most relevant<br /> information. Please accept cookies for optimal performance.
       </CookieConsent> */}
      <CustomCookieConsent />
    </>
  );
}

export default App;
